import { formatISO, isWithinInterval } from 'date-fns';
import { flattenDeep, get, isObject, keys } from 'lodash';
import { dateRangeSeparator } from '../../utils/constants';

export const filterTypes = {
  string: 'string',
  dateRange: 'dateRange',
};

const typeToDefaultValueMapping = {
  [filterTypes.string]: '',
  [filterTypes.dateRange]: '',
};

const typeToComparatorMapping = {
  [filterTypes.string]: (dataValue, filterValue) => {
    if (dataValue) {
      const a = dataValue.toLowerCase();
      const b = filterValue.toLowerCase();
      return a.includes(b);
    }
  },
  [filterTypes.dateRange]: (dataValue, filterValue) => {
    const [start, end] = filterValue.split(dateRangeSeparator);
    const isoDate = formatISO(new Date(dataValue), { representation: 'date' });
    return isWithinInterval(new Date(isoDate), {
      start: new Date(start),
      end: new Date(end),
    });
  },
};

const getFieldsPaths = (f, ancestry = '') =>
  keys(f).map((key) => {
    if (isObject(f[key])) return getFieldsPaths(f[key], ancestry + key);
    return `${ancestry}${ancestry && '.'}${key}`;
  });

export const filter = (data, filters, table) => {
  const fieldsPaths = flattenDeep(getFieldsPaths(filters)) as string[];
  return data.filter((i) => {
    for (const path of fieldsPaths) {
      const dataValue = get(i, path);
      const filterValue = get(filters, path);
      const type = get(table, path);
      if (!filterValue) continue;
      if (!typeToComparatorMapping[type](dataValue, filterValue)) return false;
    }
    return true;
  });
};

export const createInitialValues = (tables) => {
  const initialFilterValues = {};

  keys(tables).forEach((key) => {
    const type = tables[key];
    if (isObject(type)) initialFilterValues[key] = createInitialValues(type);
    else initialFilterValues[key] = typeToDefaultValueMapping[type];
  });

  return initialFilterValues;
};
