import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BlockUI } from 'web-core/lib/web-components';
import { PageHeader } from 'web-core/lib/web-components/PageHeader/PageHeader';
import { useTypedSelector } from '../../app/rootReducer';
import { fetchingStatesSelectors } from '../../store/fetchingStatesSlice';
import { fetchReviews, reviewsSelectors } from '../../store/reviewsSlice';
import { fetchingStatuses } from '../../utils/constants';
import { ReviewFilterForm } from './ReviewFilterForm';
import { ReviewList } from './ReviewList';
import './style.scss';

export const Reviews: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reviews = useTypedSelector(reviewsSelectors.filteredReviews);

  const reviewsFetchingState = useTypedSelector(
    fetchingStatesSelectors.reviews
  );

  const isFetching = reviewsFetchingState === fetchingStatuses.pending;

  useEffect(() => {
    dispatch(fetchReviews());
  }, [dispatch]);

  return (
    <BlockUI isBlocked={isFetching}>
      <div className="reviews-tab h-100 w-100">
        <div className="reviews-tab__content px-3 pt-3">
          <PageHeader title={t('reviews.header')} buttons={<></>} />
          <ReviewList reviews={reviews} />
        </div>
        <ReviewFilterForm />
      </div>
    </BlockUI>
  );
};
