import { IError } from '../types';

export const fetchingStatuses = {
  pending: 'pending' as const,
  rejected: 'rejected' as const,
  fulfilled: 'fulfilled' as const,
  idle: 'idle' as const,
};

// https://date-fns.org/v2.14.0/docs/format
export const dateFormats = {
  dateAndTime: 'Pp' as const,
  date: 'PP' as const,
  numDate: 'P' as const,
};

export const titleDelay = {
  show: 700 as const,
  hide: 10000 as const,
};

export const initialError: IError = {
  error: null,
  payload: null,
};

export const errorTypes = {
  auth: 'auth' as const,
  common: 'common' as const,
};

export const browserRoutes = {
  root: '/' as const,
  login: '/login' as const,
  features: '/features' as const,
  evaluations: '/evaluations' as const,
  reviews: '/reviews' as const,
  productReleases: '/product-releases' as const,
  downloads: '/downloads' as const,
  operationSystems: '/operation-systems' as const,
  userActions: '/user-actions' as const,
  filteredIps: '/filtered-ips' as const,
};

export const themes = {
  themeDark: 'theme-dark' as const,
  themeLight: 'theme-light' as const,
};

export const languages = {
  en: 'en' as const,
  ru: 'ru' as const,
};

export const localStorageKeys = {
  theme: 'theme' as const,
  accessToken: 'access_token' as const,
};

export const modalStateTypes = {
  createFeature: 'createFeature' as const,
  updateFeature: 'updateFeature' as const,
  logoutConfirm: 'logoutConfirm' as const,
  createProductRelease: 'createProductRelease' as const,
  updateProductRelease: 'updateProductRelease' as const,
  createFilteredIp: 'createFilteredIp' as const,
  updateFilteredIp: 'updateFilteredIp' as const,
  removeFilteredIp: 'removeFilteredIp' as const,
  featuresFilter: 'featuresFilter' as const,
  productReleasesFilter: 'productReleasesFilter' as const,
  operationSystemsFilter: 'operationSystemsFilter' as const,
  userActionsFilter: 'userActionsFilter' as const,
  filteredIpsFilter: 'filteredIpsFilter' as const,
};

export const mediaQueries = {
  sm: '(max-width: 489px)' as const,
  md: '(max-width: 991px)' as const,
};

export const dateRangeSeparator = '&&';

export enum LicenseFilter {
  All = '',
  WithLicense = 'withLicense',
  NoLicense = 'noLicense',
}
