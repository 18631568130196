import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBody, ModalHeader } from 'reactstrap';
import { IModalProps } from '../../../types';
import { UpdateForm } from '../../Features/UpdateForm';

export const UpdateFeature: FunctionComponent<IModalProps> = (props) => {
  const { t } = useTranslation();

  const { hideModal, data } = props;

  return (
    <>
      <ModalHeader className="font-montserrat m-0 border-0">
        {t('updateFeature.header', { name: data.name })}
      </ModalHeader>
      <ModalBody>
        <UpdateForm selectedFeature={data.name} onCancel={hideModal} />
      </ModalBody>
    </>
  );
};
