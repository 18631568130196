import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBody, ModalHeader } from 'reactstrap';
import { IModalProps } from '../../../types';
import { FilterFilteredIpsTableForm } from '../../FilteredIps/FilterFilteredIpsTableForm';

export const FilterFilteredIps: FunctionComponent<IModalProps> = (props) => {
  const { hideModal } = props;
  const { t } = useTranslation();

  return (
    <>
      <ModalHeader className="font-montserrat m-0 border-0">
        {t('filteredIps.modals.filter.header')}
      </ModalHeader>
      <ModalBody>
        <FilterFilteredIpsTableForm onCancel={hideModal} />
      </ModalBody>
    </>
  );
};
