import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { format, subDays, subYears } from 'date-fns';
import { reviewsApi } from '../api';
import { RootState } from '../app/rootReducer';
import { filterByDateRange } from '../lib/dateFns';
import { ReviewDto } from '../types/dto';
import { asyncThunkHandler } from '../utils/sliceHelpers';

const sliceName = 'reviews';

const adapter = createEntityAdapter<ReviewDto>({
  sortComparer: (a, b) => a.id - b.id,
});

export interface IReviewsState {
  filter: {
    selectedRange: string;
    productNames: number[];
    productVersions: ReviewDto['productVersion'][];
  };
  initialValues: {
    selectedRange: string;
    productNames: number[];
    productVersions: ReviewDto['productVersion'][];
  };
}

const currentDate = format(subDays(new Date(), 1), 'yyyy-MM-dd');
const dateMonthAgo = format(subYears(new Date(), 1), 'yyyy-MM-dd');

export const initialState: IReviewsState = {
  filter: {
    selectedRange: `${dateMonthAgo}&&${currentDate}`,
    productNames: [],
    productVersions: [],
  },
  initialValues: {
    selectedRange: `${dateMonthAgo}&&${currentDate}`,
    productNames: [],
    productVersions: [],
  },
};

export const fetchReviews = createAsyncThunk<ReviewDto[], undefined>(
  `${sliceName}/fetchAll`,
  asyncThunkHandler(reviewsApi.getAll)
);

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(initialState),
  reducers: {
    setFilterFieldValue: (
      state,
      action: PayloadAction<{
        field: keyof IReviewsState['filter'];
        value: any;
      }>
    ) => {
      const { field, value } = action.payload;
      // @ts-ignore
      state.filter[field] = value;
    },
    resetFilter: (state) => {
      state.filter = state.initialValues;
    },
    setFilterValue: (
      state,
      action: PayloadAction<{ filterState: IReviewsState['filter'] }>
    ) => {
      state.filter = action.payload.filterState;
    },
    selectRange: (state, action: PayloadAction<{ range: string }>) => {
      state.filter.selectedRange = action.payload.range;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReviews.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload);
      action.payload.forEach((review) => {
        if (!state.initialValues.productNames.includes(review.product.id))
          state.initialValues.productNames.push(review.product.id);
        if (
          !state.initialValues.productVersions.includes(review.productVersion)
        )
          state.initialValues.productVersions.push(review.productVersion);
      });
      state.initialValues.productVersions.sort();

      state.filter.productNames = state.initialValues.productNames;
      state.filter.productVersions = state.initialValues.productVersions;
    });
  },
});

const selectors = adapter.getSelectors((state: RootState) => state[sliceName]);

const productNamesSelector = (state: RootState) =>
  state[sliceName].filter.productNames;
const productVersionsSelector = (state: RootState) =>
  state[sliceName].filter.productVersions;
const selectedRangeSelector = (state: RootState) =>
  state[sliceName].filter.selectedRange;

const filteredReviewsSelector = createSelector(
  [selectors.selectAll, productNamesSelector, productVersionsSelector],
  (reviews, productNames, productVersions) => {
    const result = [];
    reviews.forEach((review) => {
      if (
        productNames.includes(review.product.id) &&
        productVersions.includes(review.productVersion)
      )
        result.push(review);
    });
    return result;
  }
);

const filteredByDateSelector = createSelector(
  [filteredReviewsSelector, selectedRangeSelector],
  (reviews, dateRange) => {
    if (!dateRange) return [];
    return filterByDateRange(reviews, 'time', dateRange);
  }
);

export const reviewsSelectors = {
  ...selectors,
  selectById: (id: ReviewDto['id']) => (state: RootState) =>
    selectors.selectById(state, id),
  filter: (state: RootState) => state[sliceName].filter,
  initialValues: (state: RootState) => state[sliceName].initialValues,
  filteredReviews: (state: RootState) => filteredByDateSelector(state),
};

export const reviewsActions = slice.actions;

export default slice.reducer;
