import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PageHeader } from 'web-core/lib/web-components/PageHeader/PageHeader';
import { useTypedSelector } from '../../app/rootReducer';
import {
  fetchOperationSystems,
  operationSystemsActions,
  operationSystemsSelectors,
} from '../../store/operationSystemsSlice';
import { filtersSelectors } from '../../store/tables/filtersSlice';
import { OperationSystemsDto } from '../../types/dto';
import { LicenseFilter as LicenseFilterValues } from '../../utils/constants';
import { LicenseFilterSelect } from '../common/LicenseFilterSelect/LicenseFilterSelect';
import { OperationSystemsChart } from './OperationSystemsChart';
import { OperationSystemsTable } from './OperationSystemsTable';
import { OperationSystemsTableButtonGroup } from './OperationSystemsTableButtonGroup';
import s from './styles.module.scss';
import './styles.scss';

export const OperationSystems = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOperationSystems());
  }, [dispatch]);

  const operationSystems: OperationSystemsDto[] = useTypedSelector(
    filtersSelectors.filteredOperationSystems
  );
  const licenseFilter: LicenseFilterValues = useTypedSelector(
    operationSystemsSelectors.licenseFilter
  );

  const getExtendedRelevantOperationSystems = (operationSystems) => {
    let sum = operationSystems.reduce(
      (prevVal, currVal: OperationSystemsDto) => {
        return prevVal + currVal.instanceCount;
      },
      0
    );

    let othersCount = 0;
    const relevantOperationSystems = [];

    operationSystems.forEach((operationSystem) => {
      if ((operationSystem.instanceCount * 100) / sum > 1)
        relevantOperationSystems.push(operationSystem);
      else othersCount += operationSystem.instanceCount;
    });

    return {
      relevantOperationSystems,
      othersCount,
    };
  };

  const {
    relevantOperationSystems,
    othersCount,
  } = getExtendedRelevantOperationSystems(operationSystems);

  const handleLicenseFilterChange = (value) => {
    dispatch(operationSystemsActions.setLicenseFilter(value));
  };

  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column h-100 w-100 px-3 pt-3 overflow-hidden">
      <div>
        <PageHeader
          buttons={
            <div>
              <OperationSystemsTableButtonGroup />
            </div>
          }
          title={t('operationSystems.header')}
        />
      </div>
      <LicenseFilterSelect
        value={licenseFilter}
        onChange={handleLicenseFilterChange}
        translationPrefix="operationSystems"
      />
      <div className={s['operating-systems']}>
        <div className={s['operating-systems__table']}>
          <OperationSystemsTable />
        </div>
        <div className={s['operating-systems__chart']}>
          <OperationSystemsChart
            relevantOperationSystems={relevantOperationSystems}
            othersCount={othersCount}
          />
        </div>
      </div>
    </div>
  );
};
