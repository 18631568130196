import { useFormik } from 'formik';
import { noop } from 'lodash';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../app/rootReducer';
import {
  filtersSelectors,
  resetValues,
  setValue,
} from '../../store/tables/filtersSlice';

type FilterFeaturesTableFormProps = {
  onCancel: () => void;
};
export const FilterFeaturesTableForm: FunctionComponent<FilterFeaturesTableFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { onCancel } = props;

  const featuresFilters = useTypedSelector(filtersSelectors.features);

  const formik = useFormik({
    initialValues: featuresFilters,
    onSubmit: (values) => {
      dispatch(setValue({ table: 'features', filters: values }));
      onCancel();
    },
  });

  const handleReset = () => {
    dispatch(resetValues({ table: 'features' }));
    formik.resetForm();
  };

  useEffect(() => {
    formik.resetForm({ values: featuresFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresFilters]);

  const isSubmitButtonDisabled = !formik.dirty;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-group row font-ag2">
        <label
          htmlFor="name"
          className="col-sm-4 col-form-label text-overflow-ellipsis gray-color-2"
        >
          {t('features.featureName')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="form-group row justify-content-end">
        <div className="mr-3">
          <button
            type="reset"
            className="btn btn-primary mr-1"
            onClick={handleReset}
          >
            {t('common.reset')}
          </button>
          <button
            type="button"
            className="btn btn-secondary mr-1"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitButtonDisabled}
          >
            {t('common.apply')}
          </button>
        </div>
      </div>
    </form>
  );
};

FilterFeaturesTableForm.defaultProps = {
  onCancel: noop,
};
