import { useTypedSelector } from 'app/rootReducer';
import { max as _max, min as _min } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadsSelectors } from 'store/downloads/downloadsSlice';

interface IDownloadsAnalyticsProps {
  totalDownloads;
  diagramDataKeys: string[];
  selectedYears: string[];
  dataForDiagram;
  dataForDiagramMonths;
  dataKeys: string[];
}

const analyse = (values: number[]) => {
  if (!values) {
    return {
      min: 0,
      max: 0,
      avg: 0,
    };
  }
  const getStandardDeviation = (array) => {
    if (!array.length) return 0;
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );
  };

  const deviation = getStandardDeviation(values);

  const sum = values.reduce((a, b) => {
    return a + b;
  }, 0);

  const avg = Math.floor(sum / values.length || 0);

  const min = Math.floor(_min(values));
  const max = Math.floor(_max(values));

  return {
    min,
    max,
    avg,
    deviation,
  };
};

export const DownloadsAnalytics: React.FC<IDownloadsAnalyticsProps> = (
  props
) => {
  const { t } = useTranslation();

  const {
    dataKeys,
    totalDownloads,
    diagramDataKeys,
    selectedYears,
    dataForDiagram,
    dataForDiagramMonths,
  } = props;

  const { selectedPeriod } = useTypedSelector(downloadsSelectors.selectState);

  const year = useMemo(() => {
    return selectedYears[selectedYears.length - 1] ?? new Date().getFullYear();
  }, [selectedYears]);

  const compareToYear = useMemo(() => {
    return selectedYears[selectedYears.length - 2];
  }, [selectedYears]);

  const downloadsObject = useMemo(() => {
    const downloadsObject = {};
    selectedYears.forEach((year) => {
      dataKeys.forEach((dataKey) => {
        downloadsObject[year] = downloadsObject[year] ?? {};
        downloadsObject[year][dataKey] =
          totalDownloads[`${dataKey} (${year})`] ?? 0;
      });
    });
    return downloadsObject;
  }, [selectedYears, totalDownloads, dataKeys]);

  const generateComparisionText = useCallback(
    (downloads, downloadsToCompare) => {
      if (!Number.isFinite(downloads / downloadsToCompare)) return;
      if (downloads === 0) return;
      if (downloads > downloadsToCompare) {
        const percent = ((downloads / downloadsToCompare) * 100).toFixed(0);
        return (
          <span style={{ color: '#24957C' }}> (+{Number(percent) - 100}%)</span>
        );
      } else if (downloads < downloadsToCompare) {
        const percent = ((downloads / downloadsToCompare) * 100).toFixed(0);
        return (
          <span style={{ color: '#ED4B3F' }}> (-{100 - Number(percent)}%)</span>
        );
      }
    },
    []
  );

  return (
    !!diagramDataKeys.length && (
      <>
        <div className="mt-3">
          {t('downloads.analysis.forTheSelectedPeriod')} <b>{year}</b>{' '}
          {t('downloads.analysis.inYear')}
          {compareToYear &&
            dataKeys
              .map((dk) => {
                return !!downloadsObject?.[compareToYear]?.[dk];
              })
              .some((v) => !!v) && (
              <span className="gray-color">
                {' '}
                ({t('downloads.analysis.comparedTo')} <b>{compareToYear}</b>)
              </span>
            )}
          :
        </div>
        <div className="mt-3 ml-6 d-flex">
          <b className="mr-1"> {t('downloads.analysis.downloads')}:</b>
          {dataKeys.map((dataKey, i) => {
            return (
              <div key={dataKey} className="mr-1">
                {dataKey} — <b>{downloadsObject[year][dataKey]}</b>
                {compareToYear && (
                  <>
                    {generateComparisionText(
                      downloadsObject[year][dataKey],
                      downloadsObject[compareToYear][dataKey]
                    )}
                  </>
                )}
                {i !== dataKeys.length - 1 && <> /</>}
              </div>
            );
          })}
        </div>
        <div className="mt-2 ml-6 d-flex">
          <b className="mr-1"> {t('downloads.analysis.statistics')}: </b>
          {dataKeys.map((dataKey, i) => {
            return (
              <div key={dataKey} className="mr-1">
                {(() => {
                  const analysed = analyse(
                    (selectedPeriod === 'month'
                      ? dataForDiagramMonths
                      : dataForDiagram
                    ).map((entry) => {
                      return entry[`${dataKey} (${year})`];
                    })
                  );

                  return (
                    <>
                      {dataKey} — {t('downloads.analysis.inAvg')}:{' '}
                      <b>{analysed.avg}</b>
                      {!Number.isNaN(analysed.min) &&
                        !Number.isNaN(analysed.max) && (
                          <>
                            , min - max:
                            <b>
                              {' '}
                              {analysed.min} - {analysed.max}
                            </b>
                          </>
                        )}
                    </>
                  );
                })()}

                {i !== dataKeys.length - 1 && <> /</>}
              </div>
            );
          })}
        </div>
      </>
    )
  );
};
