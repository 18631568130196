import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/rootReducer';
import { featuresSelectors } from '../featuresSlice';
import { filteredIpsSelectors } from '../filteredIpsSlice';
import { operationSystemsSelectors } from '../operationSystemsSlice';
import { productReleasesSelectors } from '../productReleasesSlice';
import { userActionsSelectors } from '../userActionsSlice';
import { createInitialValues, filter, filterTypes } from './filterFunctions';

type ITables = {
  features: {
    name: string;
  };
  productReleases: {
    product: {
      name: string;
    };
    version: string;
    date: string;
  };
  operationSystems: {
    system: { name: string; version: string; arch: string };
  };
  userActions: {
    context: { name: string };
    sender: { name: string };
  };
  filteredIps: {
    ip: string;
    type: string;
  };
};

const tables = {
  features: {
    name: filterTypes.string,
  },
  productReleases: {
    product: {
      name: filterTypes.string,
    },
    version: filterTypes.string,
    date: filterTypes.dateRange,
  },
  operationSystems: {
    system: {
      name: filterTypes.string,
      version: filterTypes.string,
      arch: filterTypes.string,
    },
  },
  userActions: {
    context: { name: filterTypes.string },
    sender: { name: filterTypes.string },
  },
  filteredIps: {
    ip: filterTypes.string,
    type: filterTypes.string,
  },
};

const initialState = createInitialValues(tables) as ITables;

const sliceName = 'filterTables';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setValue: (
      state,
      action: PayloadAction<{
        table: keyof ITables;
        filters: ITables[keyof ITables];
      }>
    ) => {
      const { table, filters } = action.payload;
      // @ts-ignore
      state[table] = filters;
    },
    resetValues: (state, action: PayloadAction<{ table: keyof ITables }>) => {
      const { table } = action.payload;
      // @ts-ignore
      state[table] = initialState[table];
    },
  },
});

export const { setValue, resetValues } = slice.actions;

const featuresFiltersSelector = (state: RootState) => state[sliceName].features;
const productReleasesFiltersSelector = (state: RootState) =>
  state[sliceName].productReleases;
const operationSystemsFiltersSelector = (state: RootState) =>
  state[sliceName].operationSystems;
const userActionsFiltersSelector = (state: RootState) =>
  state[sliceName].userActions;
const filteredIpsFiltersSelector = (state: RootState) =>
  state[sliceName].filteredIps;

const filteredFeaturesSelector = createSelector(
  featuresSelectors.selectAll,
  featuresFiltersSelector,
  (data, filters) => {
    return filter(data, filters, tables.features);
  }
);

const filteredProductReleasesSelector = createSelector(
  productReleasesSelectors.selectAll,
  productReleasesFiltersSelector,
  (data, filters) => {
    return filter(data, filters, tables.productReleases);
  }
);

const filteredOperationSystemsSelector = createSelector(
  operationSystemsSelectors.selectOperationSystemsByLicenseFilter,
  operationSystemsFiltersSelector,
  (data, filters) => filter(data, filters, tables.operationSystems)
);

const filteredUserActionsSelector = createSelector(
  userActionsSelectors.selectUserActionsByLicenseFilter,
  userActionsFiltersSelector,
  (data, filters) => {
    return filter(data, filters, tables.userActions);
  }
);

const filteredFilteredIpsSelector = createSelector(
  filteredIpsSelectors.selectAll,
  filteredIpsFiltersSelector,
  (data, filters) => {
    return filter(data, filters, tables.filteredIps);
  }
);

export const filtersSelectors = {
  features: featuresFiltersSelector,
  filteredFeatures: filteredFeaturesSelector,

  productReleases: productReleasesFiltersSelector,
  filteredProductReleases: filteredProductReleasesSelector,

  operationSystems: operationSystemsFiltersSelector,
  filteredOperationSystems: filteredOperationSystemsSelector,

  userActions: userActionsFiltersSelector,
  filteredUserActions: filteredUserActionsSelector,

  filteredIps: filteredIpsFiltersSelector,
  filteredFilteredIps: filteredFilteredIpsSelector,
};

export default slice.reducer;
