import { FeatureDto } from '../types/dto';
import { requestWithAuth } from './config';

const resource = '/features';

export const getAll = () => {
  return requestWithAuth.get(resource);
};

export const getById = (data: { id: number }) => {
  return requestWithAuth.get(`${resource}/${data.id}`);
};

export const create = (data: Partial<FeatureDto>) => {
  return requestWithAuth.post(resource, data);
};

export const update = (data: Partial<FeatureDto>) => {
  const { id, ...rest } = data;
  return requestWithAuth.put(`${resource}/${id}`, rest);
};

export const getFeatureEvaluations = (data: { id: number }) => {
  return requestWithAuth.get(`${resource}/${data.id}/evaluations`);
};

export const fetchFeaturesWithEvaluationsCount = () => {
  return requestWithAuth.get(`${resource}/evaluations/count`);
};
