import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser, User } from 'utils/JWToken';
import { ButtonGroup } from 'web-core/lib/web-components';
import { IButtonGroupItem } from 'web-core/lib/web-components/ButtonGroup/ButtonGroup';
import { add, filter, pencil, reset } from 'web-icons/svgIcons';
import { useTypedSelector } from '../../app/rootReducer';
import { commonActions } from '../../store/commonSlice';
import { fetchFeatures } from '../../store/featuresSlice';
import { productReleasesSelectors } from '../../store/productReleasesSlice';
import { filtersSelectors } from '../../store/tables/filtersSlice';
import { modalStateTypes } from '../../utils/constants';
import { getFilterBadgeValue } from '../../utils/utils';

export const ProductReleasesTableButtonGroup = () => {
  const dispatch = useDispatch();
  const selectedProductReleaseId = useTypedSelector(
    productReleasesSelectors.selectedId
  );
  const selectedProductRelease = useTypedSelector(
    productReleasesSelectors.selectById(selectedProductReleaseId)
  );
  const productReleasesFilters = useTypedSelector(
    filtersSelectors.productReleases
  );

  const user = useGetCurrentUser();

  const filtersBadgeValue = useMemo(
    () => getFilterBadgeValue(productReleasesFilters),
    [productReleasesFilters]
  );

  const handleCreate = () => {
    dispatch(
      commonActions.showModal({
        type: modalStateTypes.createProductRelease,
      })
    );
  };

  const handleUpdate = () => {
    dispatch(
      commonActions.showModal({
        type: modalStateTypes.updateProductRelease,
        data: selectedProductRelease,
      })
    );
  };

  const handleFilter = () => {
    dispatch(
      commonActions.showModal({
        type: modalStateTypes.productReleasesFilter,
      })
    );
  };

  const handleReset = () => {
    dispatch(fetchFeatures());
  };

  // const tKey = 'productReleases.buttonGroup';

  const buttons = [
    {
      id: 'resetButton',
      onClick: handleReset,
      icon: reset,
      show: true,
    },
    {
      id: 'updateButton',
      onClick: handleUpdate,
      icon: pencil,
      disabled: selectedProductReleaseId === null,
      show: User.isWriter(user),
    },
    {
      id: 'filterButton',
      onClick: handleFilter,
      icon: filter,
      badge: {
        type: 'info' as const,
        value: filtersBadgeValue,
        show: filtersBadgeValue !== 0,
      },
    },
    {
      id: 'createButton',
      onClick: handleCreate,
      icon: add,
      show: User.isWriter(user),
      color: 'primary',
    },
  ] as IButtonGroupItem[];

  return <ButtonGroup buttons={buttons} />;
};
