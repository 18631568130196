import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBody, ModalHeader } from 'reactstrap';
import { IModalProps } from '../../../types';

import { CreateForm } from '../../ProductReleases/CreateForm';

export const CreateProductRelease: FunctionComponent<IModalProps> = (props) => {
  const { t } = useTranslation();

  const { hideModal } = props;

  return (
    <>
      <ModalHeader className="font-montserrat m-0 border-0">
        {t('createProductRelease.header')}
      </ModalHeader>
      <ModalBody>
        <CreateForm onCancel={hideModal} />
      </ModalBody>
    </>
  );
};
