export enum EDownloadsRouterPages {
  Browse = 0,
  Compare = 1,
}

export enum ESelectedProduct {
  viewer = 'DICOM Viewer',
  server = 'DICOM Server',
  all = 'all',
}

export enum ESelectedType {
  product = 'product',
  architecture = 'arch',
  os = 'os',
  edition = 'edition',
}

export enum EChartView {
  linear = 'linear',
  monotone = 'monotone',
  step = 'step',
  basis = 'basis',
  natural = 'natural',
}
