import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser, User } from 'utils/JWToken';
import { ButtonGroup } from 'web-core/lib/web-components';
import { IButtonGroupItem } from 'web-core/lib/web-components/ButtonGroup/ButtonGroup';
import { add, filter, pencil, remove, reset } from 'web-icons/svgIcons';
import { useTypedSelector } from '../../app/rootReducer';
import { commonActions } from '../../store/commonSlice';
import {
  fetchFilteredIps,
  filteredIpsSelectors,
} from '../../store/filteredIpsSlice';
import { filtersSelectors } from '../../store/tables/filtersSlice';
import { modalStateTypes } from '../../utils/constants';
import { getFilterBadgeValue } from '../../utils/utils';

export const FilteredIpsTableButtonGroup = () => {
  const dispatch = useDispatch();
  const selectedFilteredIpId = useTypedSelector(
    filteredIpsSelectors.selectedFilteredIpId
  );
  const selectedFilteredIp = useTypedSelector(
    filteredIpsSelectors.selectById(selectedFilteredIpId)
  );
  const filteredIpsFilters = useTypedSelector(filtersSelectors.filteredIps);
  const user = useGetCurrentUser();

  const filtersBadgeValue = useMemo(
    () => getFilterBadgeValue(filteredIpsFilters),
    [filteredIpsFilters]
  );

  const handleCreateFilteredIp = () => {
    dispatch(
      commonActions.showModal({
        type: modalStateTypes.createFilteredIp,
      })
    );
  };

  const handleUpdateFilteredIp = () => {
    dispatch(
      commonActions.showModal({
        type: modalStateTypes.updateFilteredIp,
        data: selectedFilteredIp,
      })
    );
  };

  const handleFilter = () => {
    dispatch(
      commonActions.showModal({
        type: modalStateTypes.filteredIpsFilter,
      })
    );
  };

  const handleResetFilteredIps = () => {
    dispatch(fetchFilteredIps());
  };

  const handleRemoveFilteredIp = () => {
    dispatch(
      commonActions.showModal({
        type: modalStateTypes.removeFilteredIp,
        data: selectedFilteredIp,
      })
    );
  };

  const buttons = [
    {
      id: 'resetButton',
      onClick: handleResetFilteredIps,
      icon: reset,
    },
    {
      id: 'updateButton',
      onClick: handleUpdateFilteredIp,
      icon: pencil,
      disabled: selectedFilteredIpId === null,
      show: User.isWriter(user),
    },
    {
      id: 'filterButton',
      onClick: handleFilter,
      icon: filter,
      badge: {
        type: 'info' as const,
        value: filtersBadgeValue,
        show: filtersBadgeValue !== 0,
      },
    },
    {
      id: 'removeButton',
      onClick: handleRemoveFilteredIp,
      color: 'danger',
      icon: remove,
      disabled: selectedFilteredIpId === null,
      show: User.isWriter(user),
    },
    {
      id: 'createButton',
      onClick: handleCreateFilteredIp,
      icon: add,
      color: 'primary',
      show: User.isWriter(user),
    },
  ] as IButtonGroupItem[];

  return <ButtonGroup buttons={buttons} />;
};
