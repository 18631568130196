import '@openfonts/montserrat_cyrillic';
import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './app/App';
import rootReducer from './app/rootReducer';
import './i18n';
import './index.scss';
import { browserHistory } from './lib/history';
import * as serviceWorker from './serviceWorker';
export const store = configureStore({
  reducer: rootReducer,
});

ReactDOM.render(
  <Router history={browserHistory}>
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <App />
    </Provider>
    {/* </React.StrictMode> */}
  </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();

export type AppDispatch = typeof store.dispatch;
