import { format, isSameDay, isWithinInterval } from 'date-fns';
import { enGB, enUS, ru } from 'date-fns/locale';
import { IDateFormats } from '../types';
import { dateFormats } from '../utils/constants';
import { parseDateRange } from '../utils/utils';

const locales = { enUS, enGB, ru };

const getLanguageKey = (i18nLanguage) => {
  return i18nLanguage.split('-')[0];
};

export const localizedFormat = (
  date: Date | number,
  i18nLanguage,
  formatStr: IDateFormats = dateFormats.numDate
) => {
  return format(date, formatStr, {
    locale: locales[getLanguageKey(i18nLanguage)],
  });
};

export const filterByDateRange = (
  items,
  dateField: string,
  dateRange: string
) => {
  const { from, to } = parseDateRange(dateRange);
  const start = new Date(from);
  const end = new Date(to.getTime() + 1000 * 60 * 60 * 24 - 1000);

  if (isSameDay(start, end)) {
    return items.filter((item) => {
      return isSameDay(new Date(item[dateField]), start);
    });
  }
  return items.filter((item) => {
    return isWithinInterval(new Date(item[dateField]), { start, end });
  });
};
