import { useFormik } from 'formik';
import { noop } from 'lodash';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../app/rootReducer';
import { productsSelectors } from '../../store/productsSlice';
import {
  filtersSelectors,
  resetValues,
  setValue,
} from '../../store/tables/filtersSlice';
import { DatePickerRange } from '../common/DatePicker/DatePickerRange';

type FilterFeaturesTableFormProps = {
  onCancel: () => void;
};
export const FilterProductReleasesTableForm: FunctionComponent<FilterFeaturesTableFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { onCancel } = props;

  const filters = useTypedSelector(filtersSelectors.productReleases);
  const products = useTypedSelector(productsSelectors.selectAll);

  const formik = useFormik({
    initialValues: filters,
    onSubmit: (values) => {
      dispatch(setValue({ table: 'productReleases', filters: values }));
      onCancel();
    },
  });

  const handleReset = () => {
    dispatch(resetValues({ table: 'productReleases' }));
    formik.resetForm();
  };

  useEffect(() => {
    formik.resetForm({ values: filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const isSubmitButtonDisabled = !formik.dirty;

  const handleDateChange = (range) => {
    formik.setFieldValue('date', range);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="font-ag2 gray-color-2">
      <div className="form-group row">
        <label
          htmlFor="product.name"
          className="col-sm-4 col-form-label text-break"
        >
          {t('productReleases.name')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <select
            className="form-control"
            id="product.name"
            name="product.name"
            value={formik.values.product.name}
            onChange={formik.handleChange}
          >
            <option value={''}>{t('common.selectAll')}</option>
            {products.map((i) => {
              return <option value={i.name}>{i.name}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="name" className="col-sm-4 col-form-label text-break">
          {t('productReleases.version')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <input
            type="text"
            className="form-control"
            id="version"
            name="version"
            value={formik.values.version}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="name" className="col-sm-4 col-form-label text-break">
          {t('productReleases.date')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <DatePickerRange
            onChange={handleDateChange}
            value={formik.values.date}
          />
        </div>
      </div>
      <div className="form-group row justify-content-end mt-5 mb-2">
        <div className="mr-3">
          <button
            type="reset"
            className="btn btn-primary"
            onClick={handleReset}
          >
            {t('common.reset')}
          </button>
          <button
            type="button"
            className="btn btn-secondary mx-2"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitButtonDisabled}
          >
            {t('common.apply')}
          </button>
        </div>
      </div>
    </form>
  );
};

FilterProductReleasesTableForm.defaultProps = {
  onCancel: noop,
};
