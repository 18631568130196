import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/rootReducer';
import { IModalStateTypes } from '../types';

const sliceName = 'common';

interface IModalState {
  show: boolean;
  type: null | IModalStateTypes;
  data: any;
}

const initialModalState: IModalState = {
  show: false,
  type: null,
  data: {},
};

interface ICommonState {
  modalState: IModalState;
}

const initialState: ICommonState = {
  modalState: initialModalState,
};

interface IShowModalPayload {
  type: IModalStateTypes | null;
  data?: any;
}

const common = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    showModal: (state, { payload }: PayloadAction<IShowModalPayload>) => {
      const { type, data = {} } = payload;
      state.modalState.show = true;
      state.modalState.type = type;
      state.modalState.data = data;
    },
    hideModal: (state) => {
      state.modalState = initialModalState;
    },
  },
});

export const commonSelectors = {
  modalState: (state: RootState) => state.common.modalState,
};

export const commonActions = common.actions;

export default common.reducer;
