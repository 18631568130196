import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { operationSystemsApi } from '../api';
import { RootState } from '../app/rootReducer';
import { OperationSystemsDto } from '../types/dto';
import { LicenseFilter } from '../utils/constants';
import { selectByLicenseFilter } from '../utils/selectors';
import { asyncThunkHandler } from '../utils/sliceHelpers';

const sliceName = 'operationSystems';

const adapter = createEntityAdapter<OperationSystemsDto>({
  sortComparer: (a, b) => b.instanceCount - a.instanceCount,
});

interface IOperationSystemsState {
  selectedOperationSystemId: string;
  licenseFilter: LicenseFilter;
}

const initialState: IOperationSystemsState = {
  selectedOperationSystemId: null,
  licenseFilter: LicenseFilter.All,
};

export const fetchOperationSystems = createAsyncThunk<
  OperationSystemsDto[],
  undefined
>(`${sliceName}/fetchAll`, asyncThunkHandler(operationSystemsApi.getAll));

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(initialState),
  reducers: {
    selectOperationSystem: (
      state,
      { payload }: PayloadAction<{ id: string }>
    ) => {
      const { id } = payload;
      state.selectedOperationSystemId = id;
    },
    resetOperationSystem: (state) => {
      state.selectedOperationSystemId = null;
    },
    setLicenseFilter: (state, action) => {
      state.licenseFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOperationSystems.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload);
    });
  },
});

const selectors = adapter.getSelectors((state: RootState) => state[sliceName]);

const filteredByLicenseFilter = createSelector(
  [selectors.selectAll, (state: RootState) => state[sliceName].licenseFilter],
  selectByLicenseFilter
);

export const operationSystemsSelectors = {
  ...selectors,
  selectById: (id: string) => (state: RootState) =>
    selectors.selectById(state, id),
  selectedOperationSystemId: (state: RootState) =>
    state[sliceName].selectedOperationSystemId,

  licenseFilter: (state: RootState) => state[sliceName].licenseFilter,
  selectOperationSystemsByLicenseFilter: filteredByLicenseFilter,
};

export const operationSystemsActions = slice.actions;

export default slice.reducer;
