import { modalStateTypes } from '../../utils/constants';
import { CreateFeature } from './features/CreateFeature';
import { FilterFeatures } from './features/FilterFeatures';
import { UpdateFeature } from './features/UpdateFeature';
import { CreateFilteredIp } from './filteredIps/CreateFilteredIp';
import { FilterFilteredIps } from './filteredIps/FilterFilteredIps';
import { RemoveFilteredIp } from './filteredIps/RemoveFilteredIp';
import { UpdateFilteredIp } from './filteredIps/UpdateFilteredIp';
import { LogoutConfirm } from './LogoutConfirm';
import { FilterOperationSystems } from './operationSystems/FilterOperationSystems';
import { CreateProductRelease } from './prodcutReleases/CreateProductRelease';
import { FilterProductReleases } from './prodcutReleases/FilterProductReleases';
import { UpdateProductRelease } from './prodcutReleases/UpdateProductRelease';
import { FilterUserActions } from './userActions/FilterUserActions';

const modals = {
  [modalStateTypes.createFeature]: CreateFeature,
  [modalStateTypes.updateFeature]: UpdateFeature,
  [modalStateTypes.logoutConfirm]: LogoutConfirm,
  [modalStateTypes.createProductRelease]: CreateProductRelease,
  [modalStateTypes.updateProductRelease]: UpdateProductRelease,
  [modalStateTypes.createFilteredIp]: CreateFilteredIp,
  [modalStateTypes.updateFilteredIp]: UpdateFilteredIp,
  [modalStateTypes.removeFilteredIp]: RemoveFilteredIp,
  [modalStateTypes.featuresFilter]: FilterFeatures,
  [modalStateTypes.productReleasesFilter]: FilterProductReleases,
  [modalStateTypes.operationSystemsFilter]: FilterOperationSystems,
  [modalStateTypes.userActionsFilter]: FilterUserActions,
  [modalStateTypes.filteredIpsFilter]: FilterFilteredIps,
};

export const getModalComponent = (modalType) => modals[modalType];
