import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { EDownloadsRouterPages } from 'types/downloads';
import s from './styles.module.scss';

interface IRouterButtonsBar {
  prefix: string;
}

export const useRouterButtonsBar = (args: IRouterButtonsBar) => {
  const location = useLocation();
  const [t] = useTranslation();

  const RouterButtonsBarPages = [
    {
      alias: t('downloads.browse'),
      path: '',
      id: EDownloadsRouterPages.Browse,
    },
    {
      alias: t('downloads.compare'),
      path: 'compare',
      id: EDownloadsRouterPages.Compare,
    },
  ];

  const { prefix } = args;

  // Определение типа задачи по location.pathname
  const page = useMemo<{
    id: number;
    title: string;
    path: string;
  }>(() => {
    let matchedItem;
    RouterButtonsBarPages.forEach((item) => {
      const match = matchPath(location.pathname, {
        path: `${prefix}/${item.path}`,
        exact: true,
        strict: false,
      });
      if (match) matchedItem = item;
    });
    return matchedItem;
  }, [location.pathname, prefix, RouterButtonsBarPages]);

  const routerJSX = (
    <div className={cn('d-flex', s['router-buttons-bar'])}>
      {RouterButtonsBarPages.map((item) => {
        const match = matchPath(location.pathname, {
          path: `${prefix}/${item.path}`,
          exact: true,
          strict: false,
        });

        return (
          <Link
            key={item.alias}
            className="fw-600"
            to={`${prefix}/${item.path}`}
          >
            <div className="mr-3">
              <h6
                className={cn(s['router-buttons-bar__item'], {
                  [s['router-buttons-bar__item_active']]: match !== null,
                })}
              >
                {item.alias}
              </h6>
            </div>
          </Link>
        );
      })}
    </div>
  );

  return { routerJSX, page };
};
