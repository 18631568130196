import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/rootReducer';
import { IFetchingStatuses } from '../types';
import { fetchingStatuses } from '../utils/constants';
import { fetchAppDownloads, fetchDownloads } from './downloads/downloadsSlice';
import { fetchFeatureEvaluations } from './evaluationsSlice';
import {
  fetchFeatures,
  fetchFeaturesWithEvaluationsCount,
} from './featuresSlice';
import { fetchFilteredIps, fetchFilteredIpTypes } from './filteredIpsSlice';
import { fetchOperationSystems } from './operationSystemsSlice';
import { fetchProductReleases } from './productReleasesSlice';
import { fetchProducts } from './productsSlice';
import { fetchReviews } from './reviewsSlice';
import { fetchUserActions } from './userActionsSlice';

const sliceName = 'fetchingStates';

interface IObjectsFetchingStates {
  evaluations: IFetchingStatuses;
  products: IFetchingStatuses;
  features: IFetchingStatuses;
  productReleases: IFetchingStatuses;
  operationSystems: IFetchingStatuses;
  userActions: IFetchingStatuses;
  filteredIps: IFetchingStatuses;
  filteredIpTypes: IFetchingStatuses;
  downloads: IFetchingStatuses;
  reviews: IFetchingStatuses;
  appDownloads: IFetchingStatuses;
}

const initialState: IObjectsFetchingStates = {
  evaluations: fetchingStatuses.idle,
  products: fetchingStatuses.idle,
  features: fetchingStatuses.idle,
  productReleases: fetchingStatuses.idle,
  operationSystems: fetchingStatuses.idle,
  userActions: fetchingStatuses.idle,
  filteredIps: fetchingStatuses.idle,
  filteredIpTypes: fetchingStatuses.idle,
  downloads: fetchingStatuses.idle,
  reviews: fetchingStatuses.idle,
  appDownloads: fetchingStatuses.idle,
};

const addFetchingWatcher = (
  asyncThunk,
  type: keyof IObjectsFetchingStates,
  builder: ActionReducerMapBuilder<IObjectsFetchingStates>
) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state[type] = fetchingStatuses.pending;
    })
    .addCase(asyncThunk.rejected, (state) => {
      state[type] = fetchingStatuses.rejected;
    })
    .addCase(asyncThunk.fulfilled, (state) => {
      state[type] = fetchingStatuses.fulfilled;
    });
};

const fetchingStates = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addFetchingWatcher(fetchFeatureEvaluations, 'evaluations', builder);
    addFetchingWatcher(fetchProducts, 'products', builder);
    addFetchingWatcher(fetchFeatures, 'features', builder);
    addFetchingWatcher(fetchFeaturesWithEvaluationsCount, 'features', builder);
    addFetchingWatcher(fetchProductReleases, 'productReleases', builder);
    addFetchingWatcher(fetchOperationSystems, 'operationSystems', builder);
    addFetchingWatcher(fetchUserActions, 'userActions', builder);
    addFetchingWatcher(fetchFilteredIps, 'filteredIps', builder);
    addFetchingWatcher(fetchFilteredIpTypes, 'filteredIpTypes', builder);
    addFetchingWatcher(fetchDownloads, 'downloads', builder);
    addFetchingWatcher(fetchReviews, 'reviews', builder);
    addFetchingWatcher(fetchAppDownloads, 'appDownloads', builder);
  },
});

export const fetchingStatesSelectors = {
  evaluations: (state: RootState) => state[sliceName].evaluations,
  products: (state: RootState) => state[sliceName].products,
  features: (state: RootState) => state[sliceName].features,
  productReleases: (state: RootState) => state[sliceName].productReleases,
  operationSystems: (state: RootState) => state[sliceName].operationSystems,
  userActions: (state: RootState) => state[sliceName].userActions,
  filteredIps: (state: RootState) => state[sliceName].filteredIps,
  filteredIpTypes: (state: RootState) => state[sliceName].filteredIpTypes,
  downloads: (state: RootState) => state[sliceName].downloads,
  reviews: (state: RootState) => state[sliceName].reviews,
  appDownloads: (state: RootState) => state[sliceName].appDownloads,
};

export const fetchingStatesActions = fetchingStates.actions;

export default fetchingStates.reducer;
