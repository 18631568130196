import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { productsApi } from '../api';
import { RootState } from '../app/rootReducer';
import { ProductDto } from '../types/dto';
import { asyncThunkHandler } from '../utils/sliceHelpers';

const sliceName = 'products';

const adapter = createEntityAdapter<ProductDto>();

interface IProductsState {}

const initialState: IProductsState = {};

export const fetchProducts = createAsyncThunk<ProductDto[], undefined>(
  `${sliceName}/fetchAll`,
  asyncThunkHandler(productsApi.getAll)
);

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, adapter.setAll);
  },
});

const selectors = adapter.getSelectors((state: RootState) => state[sliceName]);

export const productsSelectors = {
  ...selectors,
  selectById: (id: ProductDto['id']) => (state: RootState) =>
    selectors.selectById(state, id),
};

export const productsActions = slice.actions;

export default slice.reducer;
