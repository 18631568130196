import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import RadioButtonGroup from 'web-core/lib/web-components/RadioButtonGroup/RadioButtonGroup';
import { InobitecIcon } from 'web-icons';
import { ascendingSorting, descendingSorting } from 'web-icons/svgIcons';
import { ReviewDto } from '../../types/dto';
import { Review } from './Review';
import { sortReviews } from './utils';

const sortOrderButtons = {
  name: 'sortOrder',
  options: [
    { value: 'asc', icon: ascendingSorting },
    { value: 'desc', icon: descendingSorting },
  ],
};

interface IReviewListProps {
  reviews: ReviewDto[];
}

export const ReviewList: React.FunctionComponent<IReviewListProps> = (
  props
) => {
  const { t } = useTranslation();

  const [sortField, setSortField] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');

  const { reviews } = props;

  const handleSortChange = (e) => {
    setSortField(e.target.value);
  };

  const handleChangeSortOrder = (e) => {
    setSortOrder(e.target.value);
  };

  const getOrderChoices = (attributes) =>
    attributes.options.map((option: { value: number; icon: any }) => ({
      value: option.value,
      name: attributes.name,
      label: <InobitecIcon icon={option.icon} size="1x" />,
    }));

  return (
    <div className="review-list">
      <div className={`review-list__header bg-body`}>
        <h5 className="color-gray fw-500">
          {reviews && `${t('reviews.reviewList.total')}: ${reviews.length}`}
        </h5>
        <div className="d-flex align-items-center  flex-wrap">
          <div className="text-nowrap mr-3 font-ag2 gray-color-2">
            {t('reviews.reviewList.sort.title')}:
          </div>
          <Input
            style={{ width: 100 }}
            className="mr-3"
            type="select"
            name="select"
            id="exampleSelect"
            onChange={handleSortChange}
          >
            <option value="date">
              {t('reviews.reviewList.sort.options.date')}
            </option>
            <option value="productVersion">
              {t('reviews.reviewList.sort.options.productVersion')}
            </option>
            <option value="score">
              {t('reviews.reviewList.sort.options.score')}
            </option>
          </Input>
          <div className="pt-2">
            <RadioButtonGroup
              onChange={handleChangeSortOrder}
              selectedValue={sortOrder}
            >
              {getOrderChoices(sortOrderButtons)}
            </RadioButtonGroup>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column overflow-auto w-100 mb-5">
        {reviews &&
          reviews
            .sort(sortReviews(sortField, sortOrder))
            .map((review) => <Review key={review.id} review={review} />)}
      </div>
    </div>
  );
};
