import cn from 'classnames';
import { formatISO } from 'date-fns';
import { toNumber } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useTranslation } from 'react-i18next';
import { localizedFormat } from '../../../lib/dateFns';
import './style.scss';

type DatePickerProps = {
  value?: string | Date;
  onChange: (isoDate: Date | string) => void;
};
export const DatePicker: FunctionComponent<DatePickerProps> = (props) => {
  const { value, onChange } = props;
  const dateValue = new Date(value);
  const [selectedDay, selectDay] = useState(dateValue);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    handleDayChange(dateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (date) => {
    return localizedFormat(date, i18n.language);
  };

  const handleDayChange = (date) => {
    const isoDate = formatISO(date, { representation: 'date' });
    onChange(isoDate);
  };

  const inputClasses = cn('form-control w-100');

  const component = (props) => <input className={inputClasses} {...props} />;

  const classes = {
    container: 'DayPickerInput',
    overlay: `DayPickerInput-Overlay bg-body border mt-2`,
    overlayWrapper: 'DayPickerInput-OverlayWrapper',
  };

  return (
    <DayPickerInput
      classNames={classes}
      formatDate={formatDate}
      value={formatDate(dateValue)}
      onDayChange={handleDayChange}
      component={component}
      dayPickerProps={{
        locale: i18n.language,
        months: t('dayPicker.months', { returnObjects: true }),
        weekdaysLong: t('dayPicker.weekdaysLong', { returnObjects: true }),
        weekdaysShort: t('dayPicker.weekdaysShort', { returnObjects: true }),
        firstDayOfWeek: toNumber(t('dayPicker.firstDayOfWeek')),
        labels: t('dayPicker.labels', { returnObjects: true }),
        onDayClick: selectDay,
        selectedDays: selectedDay,
      }}
    />
  );
};

DatePicker.defaultProps = {
  value: formatISO(Date.now(), { representation: 'date' }),
};
