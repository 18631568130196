import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { filteredIpsApi } from '../api';
import { RootState } from '../app/rootReducer';
import { FilteredIpsDto, FilteredIpTypesDto } from '../types/dto';
import { asyncThunkHandler } from '../utils/sliceHelpers';

const sliceName = 'filteredIps';

const adapter = createEntityAdapter<FilteredIpsDto>({
  sortComparer: (a, b) => a.id - b.id,
});

interface IFilteredIpsState {
  selectedFilteredIpId: FilteredIpsDto['id'] | null;
  filteredIpTypes: FilteredIpTypesDto;
}

const initialState: IFilteredIpsState = {
  selectedFilteredIpId: null,
  filteredIpTypes: null,
};

export const fetchFilteredIps = createAsyncThunk<FilteredIpsDto[], undefined>(
  `${sliceName}/fetchAll`,
  asyncThunkHandler(filteredIpsApi.getAll)
);

export const createFilteredIp = createAsyncThunk<
  FilteredIpsDto,
  Partial<FilteredIpsDto>
>(`${sliceName}/create`, asyncThunkHandler(filteredIpsApi.create));

export const updateFilteredIp = createAsyncThunk<
  FilteredIpsDto,
  Partial<FilteredIpsDto>
>(`${sliceName}/update`, asyncThunkHandler(filteredIpsApi.update));

export const removeFilteredIp = createAsyncThunk<
  number,
  Partial<FilteredIpsDto>
>(`${sliceName}/remove`, asyncThunkHandler(filteredIpsApi.remove));

export const fetchFilteredIpTypes = createAsyncThunk<
  FilteredIpTypesDto,
  undefined
>(`${sliceName}/fetchTypes`, asyncThunkHandler(filteredIpsApi.getTypes));

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(initialState),
  reducers: {
    selectFilteredIp: (
      state,
      { payload }: PayloadAction<{ id: null | FilteredIpsDto['id'] }>
    ) => {
      const { id } = payload;
      state.selectedFilteredIpId = id;
    },
    resetFilteredIp: (state) => {
      state.selectedFilteredIpId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilteredIps.fulfilled, adapter.setAll)
      .addCase(createFilteredIp.fulfilled, adapter.addOne)
      .addCase(updateFilteredIp.fulfilled, adapter.upsertOne)
      .addCase(removeFilteredIp.fulfilled, (state, action) => {
        adapter.removeOne(state, action.meta.arg.id);
      })
      .addCase(fetchFilteredIpTypes.fulfilled, (state, action) => {
        state.filteredIpTypes = action.payload;
      });
  },
});

const selectors = adapter.getSelectors((state: RootState) => state[sliceName]);

export const filteredIpsSelectors = {
  ...selectors,
  selectById: (id: FilteredIpsDto['id']) => (state: RootState) =>
    selectors.selectById(state, id),
  selectedFilteredIpId: (state: RootState) =>
    state[sliceName].selectedFilteredIpId,
  filteredIpTypes: (state: RootState) => state[sliceName].filteredIpTypes,
};

export const filteredIpsActions = slice.actions;

export default slice.reducer;
