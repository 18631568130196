import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BlockUI } from 'web-core/lib/web-components';
import {
  IColumnForTableComponent,
  TableComponent,
} from 'web-core/lib/web-components/TableComponent/TableComponent';
import { useTypedSelector } from '../../app/rootReducer';
import { fetchingStatesSelectors } from '../../store/fetchingStatesSlice';
import {
  operationSystemsActions,
  operationSystemsSelectors,
} from '../../store/operationSystemsSlice';
import { filtersSelectors } from '../../store/tables/filtersSlice';
import { fetchingStatuses } from '../../utils/constants';
import { Devicons } from '../common/Devicons/Devicons';

interface IOperatingSystemDTO {
  id: number;
  system: {
    name: string;
    version: string;
    arch: string;
  };
  instanceCount: number;
  hasLicense: boolean;
}

interface IOperatingSystem {
  id: number;
  systemName: string;
  systemVersion: string;
  systemArch: string;
  instanceCount: number;
  hasLicence: boolean;
}

export const OperationSystemsTable = () => {
  const dispatch = useDispatch();
  const operationSystems = useTypedSelector(
    filtersSelectors.filteredOperationSystems
  );
  const operationSystemsFetchingState = useTypedSelector(
    fetchingStatesSelectors.operationSystems
  );
  const selectedOperationSystemId = useTypedSelector(
    operationSystemsSelectors.selectedOperationSystemId
  );

  useEffect(() => {
    if (
      selectedOperationSystemId &&
      !operationSystems.find(
        (operationSystem) => operationSystem.id === selectedOperationSystemId
      )
    )
      dispatch(operationSystemsActions.resetOperationSystem());
  }, [dispatch, operationSystems, selectedOperationSystemId]);

  const [columnsState, setColumnsState] = useState<
    IColumnForTableComponent<IOperatingSystem>[]
  >([
    {
      isResizable: true,
      isSortable: true,
      style: {
        maxWidth: '10em',
      },
      dataField: 'systemName',
      customFormatter: (value) => {
        const iconStyle = { marginRight: 10 };
        let OSIcon;
        if (value.includes('windows'))
          OSIcon = <Devicons.WindowsIcon style={iconStyle} />;
        else if (value.includes('macos') || value.includes('osx'))
          OSIcon = <Devicons.AppleIcon style={iconStyle} />;
        else OSIcon = <Devicons.LinuxIcon style={iconStyle} />;
        return (
          <div className="d-flex align-items-center">
            {OSIcon}
            {value}
          </div>
        );
      },
    },
    {
      isSortable: true,
      dataField: 'systemVersion',
      isResizable: true,
    },
    {
      isSortable: true,
      dataField: 'systemArch',
      isResizable: true,
    },
    {
      isSortable: true,
      orderType: 'number',
      dataField: 'instanceCount',
      isResizable: true,
    },
  ]);

  const handleSelectRow = (id) => {
    dispatch(
      operationSystemsActions.selectOperationSystem({
        id,
      })
    );
  };

  const isFetching = operationSystemsFetchingState === fetchingStatuses.pending;

  const operatingSystemDataAdapter = operationSystems.map(
    (operatingSystem: IOperatingSystemDTO) => {
      return {
        id: operatingSystem.id,
        hasLicense: operatingSystem.hasLicense,
        systemName: operatingSystem.system.name,
        systemVersion: operatingSystem.system.version,
        systemArch: operatingSystem.system.arch,
        instanceCount: operatingSystem.instanceCount,
      };
    }
  );

  return (
    <div>
      <BlockUI isBlocked={isFetching} className="overflow-hidden">
        <div className="overflow-auto">
          <TableComponent<IOperatingSystem>
            tableName="operationSystems"
            columnsState={columnsState}
            setColumnsState={setColumnsState}
            selectedRow={selectedOperationSystemId}
            data={operatingSystemDataAdapter}
            onSelectRow={handleSelectRow}
            keyField="id"
          />
        </div>
      </BlockUI>
    </div>
  );
};
