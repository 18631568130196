import axios from 'axios';
import { JWTInterceptor } from 'web-core/lib/utils/JWTAuth';
import { browserHistory } from '../lib/history';
import { browserRoutes } from '../utils/constants';

const baseURL = '/api';

const request = axios.create({
  baseURL,
});

const requestWithAuth = axios.create({
  baseURL,
});

let jwtInterceptor = new JWTInterceptor({
  browserHistory,
  loginPageURL: browserRoutes.login,
  baseURL,
  errorMessage: 'Authorization error',
  apiURLs: {
    login: '/login',
    refreshToken: '/refresh_token',
  },
  method: 'get',
});

const addRequestRefreshTokenInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    jwtInterceptor.getInterceptor({
      checkRefresh: false,
    }),
    jwtInterceptor.handleError()
  );
};

const addResponseRedirectInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    jwtInterceptor.handleError('response')
  );
};

addResponseRedirectInterceptor(requestWithAuth);
addRequestRefreshTokenInterceptor(requestWithAuth);

export { request, requestWithAuth };
