import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { EChartView, ESelectedProduct, ESelectedType } from 'types/downloads';
import { downloadsApi } from '../../api';
import { RootState } from '../../app/rootReducer';
import { DownloadDto, ProductDto } from '../../types/dto';
import { asyncThunkHandler } from '../../utils/sliceHelpers';
import {
  filterByProduct,
  groupByArch,
  groupByEdition,
  groupByOs,
  groupByProduct,
  parseDownloads,
} from './selectors';

const sliceName = 'downloads';

const adapter = createEntityAdapter<DownloadDto>();

type IPeriod = 'day' | 'month';

interface IDownloadsState {
  selectedRange: string;
  selectedProduct: string;
  selectedType: string;
  selectedObject: string;
  selectedPeriod: IPeriod;
  selectedChartView: EChartView;
  minDate: string;
  maxDate: string;
}

const currentDate = format(subDays(new Date(), 1), 'yyyy-MM-dd');
const dateMonthAgo = format(subDays(new Date(), 31), 'yyyy-MM-dd');

const initialState: IDownloadsState = {
  selectedRange: `${dateMonthAgo}&&${currentDate}`,
  selectedProduct: ESelectedProduct.all,
  selectedType: ESelectedType.product,
  selectedObject: null,
  selectedPeriod: 'day',
  selectedChartView: EChartView.linear,
  minDate: null,
  maxDate: null,
};

export const fetchDownloads = createAsyncThunk<DownloadDto[], undefined>(
  `${sliceName}/fetchAll`,
  asyncThunkHandler(downloadsApi.getAll)
);

export interface IFetchAppDownloadsRequest {
  params: {
    from: string;
    to: string;
    productIds: string[];
  };
  accept?: 'application/json' | 'text/csv';
}
export const fetchAppDownloads = createAsyncThunk<
  DownloadDto[],
  IFetchAppDownloadsRequest
>(
  `${sliceName}/fetchAppDownloads`,
  asyncThunkHandler(downloadsApi.getAppDownloads)
);

export const addAppDownloads = createAsyncThunk<
  DownloadDto[],
  IFetchAppDownloadsRequest
>(
  `${sliceName}/addAppDownloads`,
  asyncThunkHandler(downloadsApi.getAppDownloads)
);

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(initialState),
  reducers: {
    setToInitialState: (state) => {
      state.selectedType = initialState.selectedType;
      state.selectedObject = initialState.selectedObject;
      state.selectedPeriod = initialState.selectedPeriod;
      state.selectedProduct = initialState.selectedProduct;
    },
    selectPeriod: (state, action: PayloadAction<{ period: IPeriod }>) => {
      state.selectedPeriod = action.payload.period;
    },
    selectRange: (state, action: PayloadAction<{ range: string }>) => {
      state.selectedRange = action.payload.range;
    },
    selectProduct: (
      state,
      action: PayloadAction<{ name: ProductDto['name'] }>
    ) => {
      state.selectedProduct = action.payload.name;
    },
    selectType: (state, action: PayloadAction<{ type: string }>) => {
      state.selectedType = action.payload.type;
    },
    selectObject: (state, action: PayloadAction<{ object: string }>) => {
      state.selectedObject = action.payload.object;
    },
    selectChartView: (state, action: PayloadAction<{ view: EChartView }>) => {
      state.selectedChartView = action.payload.view;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDownloads.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.minDate = action.payload[0].date;
        state.maxDate = action.payload[action.payload.length - 1].date;
      }
      // adapter.setAll(state, action.payload);
    });
    builder.addCase(fetchAppDownloads.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.minDate = action.payload[0].date;
        state.maxDate = action.payload[action.payload.length - 1].date;
      }
      adapter.setAll(state, action.payload);
    });
    builder.addCase(addAppDownloads.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload);
    });
  },
});

const selectors = adapter.getSelectors((state: RootState) => state[sliceName]);

const filteredByDateSelector = createSelector(
  selectors.selectAll,
  (state: RootState) => state[sliceName].selectedRange,
  (downloads, dateRange) => {
    if (!dateRange) return [];
    return downloads;
    // return filterByDateRange(downloads, 'date', dateRange); # Фильтрация лишняя после того, как фильтрация была перенесена на бэк
  }
);

const filteredByProductSelector = createSelector(
  filteredByDateSelector,
  (state: RootState) => state[sliceName].selectedProduct,
  filterByProduct
);

const typeMapping = {
  os: groupByOs,
  edition: groupByEdition,
  arch: groupByArch,
  product: groupByProduct,
};

const filteredByTypeSelector = createSelector(
  filteredByProductSelector,
  (state: RootState) => state[sliceName].selectedType,
  (downloads, type) => {
    return typeMapping[type](downloads);
  }
);

export const downloadsSelectors = {
  ...selectors,
  selectById: (id: DownloadDto['id']) => (state: RootState) =>
    selectors.selectById(state, id),
  selectPeriod: (state: RootState) => state[sliceName].selectedPeriod,
  selectRange: (state: RootState) => state[sliceName].selectedRange,
  selectSelectedProduct: (state: RootState) => state[sliceName].selectedProduct,
  selectSelectedType: (state: RootState) => state[sliceName].selectedType,
  selectSelectedObject: (state: RootState) => state[sliceName].selectedObject,
  selectParsedDownloads: createSelector(selectors.selectAll, parseDownloads),
  selectFilteredByDate: filteredByDateSelector,
  selectFilteredByType: filteredByTypeSelector,
  selectState: (state: RootState) => state[sliceName],
};

export const downloadsActions = slice.actions;

export default slice.reducer;
