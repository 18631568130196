import { useTypedSelector } from 'app/rootReducer';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  downloadsActions,
  downloadsSelectors,
} from 'store/downloads/downloadsSlice';
import { ESelectedProduct, ESelectedType } from 'types/downloads';
import s from '../styles.module.scss';
import { ReactComponent as PacsLogo } from './images/pacs.svg';
import { ReactComponent as ViewerLogo } from './images/viewer_pro.svg';

interface IChartSettingsProps {}

export const ChartSettings: React.FC<IChartSettingsProps> = (props) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { selectedType, selectedProduct } = useTypedSelector(
    downloadsSelectors.selectState
  );

  useEffect(() => {
    // dispatch(downloadsActions.selectPeriod({ period: 'day' }));
  }, [dispatch]);

  return (
    <div className="ml-3">
      <div className="d-flex" style={{ fontWeight: 600 }}>
        <div
          className={cn('d-flex align-items-center cursor-pointer mr-3', {
            [s['isNotSelected']]: selectedProduct !== ESelectedProduct.all,
          })}
          onClick={() => {
            dispatch(
              downloadsActions.selectProduct({
                name: ESelectedProduct.all,
              })
            );
          }}
        >
          <div className={cn('position-relative')}>
            <ViewerLogo height={24} />
            <PacsLogo height={24} style={{ marginLeft: -10 }} />
          </div>
          <div style={{ marginLeft: 8 }} className="flex-grow-1">
            {t('downloads.chartButtons.product.all')}
          </div>
        </div>
        <div
          onClick={() => {
            dispatch(
              downloadsActions.selectProduct({
                name: ESelectedProduct.viewer,
              })
            );
          }}
          className={cn('d-flex align-items-center cursor-pointer mr-3', {
            [s['isNotSelected']]: selectedProduct !== ESelectedProduct.viewer,
          })}
        >
          <ViewerLogo height={24} style={{ marginLeft: 5 }} />
          <div className="ml-2 flex-grow-1">
            {' '}
            {t('downloads.chartButtons.product.DICOMViewer')}
          </div>
        </div>
        <div
          onClick={() => {
            dispatch(
              downloadsActions.selectProduct({
                name: ESelectedProduct.server,
              })
            );
          }}
          className={cn('d-flex align-items-center cursor-pointer', {
            [s['isNotSelected']]: selectedProduct !== ESelectedProduct.server,
          })}
        >
          <PacsLogo height={24} style={{ marginLeft: 5 }} />
          <div className="ml-2 flex-grow-1">
            {' '}
            {t('downloads.chartButtons.product.DICOMServer')}
          </div>
        </div>
      </div>
      <div
        className="position-absolute d-flex align-items-center mt-1"
        style={{ right: 72 }}
      >
        <div
          onClick={() => {
            dispatch(
              downloadsActions.selectType({ type: ESelectedType.product })
            );
          }}
          className={cn('mr-2 cursor-pointer', {
            [s['isNotSelected']]: selectedType !== ESelectedType.product,
          })}
        >
          {t('downloads.chartButtons.attribute.product')}
        </div>
        <div
          onClick={() => {
            dispatch(downloadsActions.selectType({ type: ESelectedType.os }));
          }}
          className={cn('mr-2 cursor-pointer', {
            [s['isNotSelected']]: selectedType !== ESelectedType.os,
          })}
        >
          {t('downloads.chartButtons.attribute.os')}
        </div>
        <div
          onClick={() => {
            dispatch(
              downloadsActions.selectType({ type: ESelectedType.architecture })
            );
          }}
          className={cn('mr-2 cursor-pointer', {
            [s['isNotSelected']]: selectedType !== ESelectedType.architecture,
          })}
        >
          {t('downloads.chartButtons.attribute.arch')}
        </div>
        <div
          onClick={() => {
            dispatch(
              downloadsActions.selectType({ type: ESelectedType.edition })
            );
          }}
          className={cn('mr-2 cursor-pointer', {
            [s['isNotSelected']]: selectedType !== ESelectedType.edition,
          })}
        >
          {t('downloads.chartButtons.attribute.edition')}
        </div>
      </div>
    </div>
  );
};
