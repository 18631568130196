import { combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import auth from '../store/authSlice';
import common from '../store/commonSlice';
import downloads from '../store/downloads/downloadsSlice';
import errors from '../store/errrosSlice';
import evaluations from '../store/evaluationsSlice';
import features from '../store/featuresSlice';
import fetchingStates from '../store/fetchingStatesSlice';
import filteredIps from '../store/filteredIpsSlice';
import operationSystems from '../store/operationSystemsSlice';
import productReleases from '../store/productReleasesSlice';
import products from '../store/productsSlice';
import reviews from '../store/reviewsSlice';
import filterTables from '../store/tables/filtersSlice';
import userActions from '../store/userActionsSlice';

const rootReducer = combineReducers({
  features,
  evaluations,
  products,
  common,
  auth,
  errors,
  fetchingStates,
  productReleases,
  operationSystems,
  userActions,
  filteredIps,
  filterTables,
  downloads,
  reviews,
});

export type RootState = ReturnType<typeof rootReducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
