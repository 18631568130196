import { useState, useEffect } from 'react';
import { IMediaQueries } from '../types';

const useMediaQuery = (query: IMediaQueries) => {
  const [doesMatch, onSetDoesMatch] = useState(false);

  useEffect(() => {
    const onUpdateMatch = ({ matches }) => {
      onSetDoesMatch(matches);
    };

    const matcher = window.matchMedia(query);

    matcher.addEventListener('change', onUpdateMatch);

    onUpdateMatch(matcher);

    return () => {
      matcher.removeEventListener('change', onUpdateMatch);
    };
  }, [query, onSetDoesMatch]);

  return doesMatch;
};

export default useMediaQuery;
