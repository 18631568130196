import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonGroup } from 'web-core/lib/web-components';
import { filter, reset } from 'web-icons/svgIcons';
import { useTypedSelector } from '../../app/rootReducer';
import { commonActions } from '../../store/commonSlice';
import { fetchOperationSystems } from '../../store/operationSystemsSlice';
import { filtersSelectors } from '../../store/tables/filtersSlice';
import { modalStateTypes } from '../../utils/constants';
import { getFilterBadgeValue } from '../../utils/utils';

export const OperationSystemsTableButtonGroup = () => {
  const dispatch = useDispatch();

  const operationSystemsFilters = useTypedSelector(
    filtersSelectors.operationSystems
  );

  const filtersBadgeValue = useMemo(
    () => getFilterBadgeValue(operationSystemsFilters),
    [operationSystemsFilters]
  );

  const handleFilter = () => {
    dispatch(
      commonActions.showModal({
        type: modalStateTypes.operationSystemsFilter,
      })
    );
  };

  const handleResetOperationSystems = () => {
    dispatch(fetchOperationSystems());
  };

  // const tKey = 'operationSystems.buttonGroup';

  const buttons = [
    {
      id: 'resetButton',
      onClick: handleResetOperationSystems,
      icon: reset,
    },
    {
      id: 'filterButton',
      onClick: handleFilter,
      icon: filter,
      badge: {
        type: 'info' as const,
        value: filtersBadgeValue,
        show: filtersBadgeValue !== 0,
      },
    },
  ];

  return <ButtonGroup buttons={buttons} />;
};
