import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBoxes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser } from 'utils/JWToken';
import { Core as LibCore } from 'web-core/lib/web-components/index';
import { InobitecIcon } from 'web-icons';
import {
  comment,
  download,
  filteredIps,
  functional,
  functionalStats,
  operationSystems,
  starHalf,
} from 'web-icons/svgIcons';
import langREnIcon from '../assets/icons/lang-en.svg';
import langRuIcon from '../assets/icons/lang-ru.svg';
import TelemetryLogo from '../assets/logo.png';
import i18n from '../i18n';
import { commonActions } from '../store/commonSlice';
import {
  browserRoutes,
  languages,
  localStorageKeys,
  modalStateTypes,
} from '../utils/constants';
import { changeTheme } from '../utils/utils';

interface ICoreProps {
  themeContext;
}

export const Core: React.FC<ICoreProps> = (props) => {
  const { themeContext } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { theme } = useContext(themeContext);
  const [currentTheme, setCurrentTheme] = useState(theme);

  const user = useGetCurrentUser();
  const username = user.username;

  const linkToIconMapping = {
    [browserRoutes.evaluations]: <InobitecIcon icon={starHalf} size="1x" />,
    [browserRoutes.reviews]: <InobitecIcon icon={comment} size="1x" />,
    [browserRoutes.features]: <InobitecIcon icon={functional} size="1x" />,
    [browserRoutes.productReleases]: <FaBoxes />,
    [browserRoutes.downloads]: <InobitecIcon icon={download} size="1x" />,
    [browserRoutes.operationSystems]: (
      <InobitecIcon icon={operationSystems} size="1x" />
    ),
    [browserRoutes.userActions]: (
      <InobitecIcon icon={functionalStats} size="1x" />
    ),
    [browserRoutes.filteredIps]: <InobitecIcon icon={filteredIps} size="1x" />,
  };

  return (
    <LibCore
      settings={{
        navBar: {
          actions: {
            handleChangeLanguage: (key) => {
              i18n.changeLanguage(languages[key]);
            },
            handleChangeTheme: () => {
              const newThemeMapping = {
                'theme-dark': 'theme-light',
                'theme-light': 'theme-dark',
              };
              const newTheme = newThemeMapping[currentTheme];
              localStorage.setItem(localStorageKeys.theme, newTheme);
              setCurrentTheme(newTheme);
              changeTheme(newTheme);
            },
            handleLogout: () => {
              dispatch(
                commonActions.showModal({ type: modalStateTypes.logoutConfirm })
              );
            },
          },
          elements: {
            desktop: {
              logo: TelemetryLogo,
              title: <>{t('navBar.telemetry')}</>,
            },
            mobile: {
              logo: TelemetryLogo,
              title: <>{t('navBar.telemetry')}</>,
            },
          },
          general: {
            username: username,
          },
          options: {
            currentTheme: currentTheme,
            excludeRoutes: ['/', '/login'],
            languageToIconMapping: { en: langREnIcon, ru: langRuIcon },
            showFooter: true,
            themes: { themeDark: 'theme-dark', themeLight: 'theme-light' },
          },
          routes: [
            {
              path: '/features',
              title: 'features',
              icon: linkToIconMapping['/features'],
            },
            {
              path: '/evaluations',
              title: 'evaluations',
              icon: linkToIconMapping['/evaluations'],
            },
            {
              path: '/reviews',
              title: 'reviews',
              icon: linkToIconMapping['/reviews'],
            },
            {
              path: '/product-releases',
              title: 'product-releases',
              icon: linkToIconMapping['/product-releases'],
            },
            {
              path: '/downloads',
              title: 'downloads',
              icon: linkToIconMapping['/downloads'],
            },
            {
              path: '/operation-systems',
              title: 'operation-systems',
              icon: linkToIconMapping['/operation-systems'],
            },
            {
              path: '/user-actions',
              title: 'user-actions',
              icon: linkToIconMapping['/user-actions'],
            },
            {
              path: '/filtered-ips',
              title: 'filtered-ips',
              icon: linkToIconMapping['/filtered-ips'],
            },
          ],
        },
      }}
    >
      {props.children}
    </LibCore>
  );
};
