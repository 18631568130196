import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { useTypedSelector } from '../../app/rootReducer';
import { operationSystemsSelectors } from '../../store/operationSystemsSlice';

const colors = [
  '#5555bb', // blue
  '#bbbb33', // yellow
  '#338333', // green
  '#bbA533', // orange
  '#bb3333', // red
  '#833383', // purple
  '#838333', // olive
  '#33bb33', // lime
  '#833333', // maroon
  '#33bbbb', // aqua
  '#338383', // team
  '#555583', // navy
  '#bb33bb', // fushua
  '#aaaaaa', // gray
];

const ActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    t,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.system.name &&
          payload.system.name +
            ' ' +
            payload.system.version +
            ' ' +
            payload.system.arch}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {payload.system.name && (
        <>
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#666"
          >{`${t('operationSystems.chart.count')}: ${value}`}</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#666"
          >
            {`(${t('operationSystems.chart.rate')} ${(percent * 100).toFixed(
              2
            )}%)`}
          </text>
        </>
      )}
    </g>
  );
};

export const OperationSystemsChart = (props) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const selectedOperationSystemId = useTypedSelector(
    operationSystemsSelectors.selectedOperationSystemId
  );

  const { relevantOperationSystems, othersCount } = props;

  const onPieEnter = (data, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    if (selectedOperationSystemId)
      setActiveIndex(
        getIndexById(relevantOperationSystems, selectedOperationSystemId)
      );
  };

  useEffect(() => {
    if (selectedOperationSystemId)
      setActiveIndex(
        getIndexById(relevantOperationSystems, selectedOperationSystemId)
      );
  }, [selectedOperationSystemId, relevantOperationSystems]);

  const getIndexById = (operationSystems, id: string) => {
    for (let i = 0; i < operationSystems.length; i++) {
      if (operationSystems[i].id === id) return i;
    }
    return operationSystems.length;
  };

  return (
    <ResponsiveContainer height={800}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={<ActiveShape t={t} />}
          data={[
            ...relevantOperationSystems,
            {
              system: {
                name: t('operationSystems.chart.others'),
                version: '',
                arch: '',
              },
              instanceCount: othersCount,
            },
          ]}
          dataKey="instanceCount"
          nameKey="id"
          cx="50%"
          cy="50%"
          outerRadius={225}
          innerRadius={120}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          startAngle={90}
          endAngle={450}
        >
          {colors.map((color: string, index: number) => (
            <Cell
              key={index}
              fill={colors[(colors.length - index) % colors.length]}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
