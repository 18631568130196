import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  nanoid,
  createSelector,
} from '@reduxjs/toolkit';
import { ceil, groupBy, keys, sumBy } from 'lodash';
import compareVersions from 'tiny-version-compare';
import { featuresApi } from '../api';
import { RootState } from '../app/rootReducer';
import { IGroupedEvaluation } from '../types/data';
import { EvaluationDto, FeatureDto, ProductDto } from '../types/dto';
import { asyncThunkHandler } from '../utils/sliceHelpers';

const sliceName = 'evaluations';

interface IEvaluationState {
  selectedProductVersion: null | IGroupedEvaluation['productVersion'];
  selectedFeatureId: null | FeatureDto['id'];
  selectedProductId: null | ProductDto['id'];
  isFirstLoad: boolean;
}

const initialState: IEvaluationState = {
  selectedProductVersion: null,
  selectedFeatureId: null,
  selectedProductId: null,
  isFirstLoad: true,
};

const adapter = createEntityAdapter<EvaluationDto>();

export const fetchFeatureEvaluations = createAsyncThunk<
  EvaluationDto[],
  { id: EvaluationDto['id'] }
>(
  `${sliceName}/fetchEvaluations`,
  asyncThunkHandler(featuresApi.getFeatureEvaluations)
);

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(initialState),
  reducers: {
    selectFeatureId: (
      state,
      { payload }: PayloadAction<{ id: null | FeatureDto['id'] }>
    ) => {
      const { id } = payload;
      state.selectedFeatureId = id;
    },
    selectProductId: (
      state,
      { payload }: PayloadAction<{ id: null | ProductDto['id'] }>
    ) => {
      const { id } = payload;
      state.selectedProductId = id;
    },
    selectProductVersion: (
      state,
      {
        payload,
      }: PayloadAction<{ version: IGroupedEvaluation['productVersion'] }>
    ) => {
      const { version } = payload;
      state.selectedProductVersion = version;
    },
    setFirstLoad: (
      state,
      { payload }: PayloadAction<{ isFirstLoad: boolean }>
    ) => {
      const { isFirstLoad } = payload;
      state.isFirstLoad = isFirstLoad;
    },
  },
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(fetchFeatureEvaluations.fulfilled, adapter.setAll);
  },
});

const selectors = adapter.getSelectors((state: RootState) => state[sliceName]);

const selectedProductIdSelector = (state: RootState) =>
  state[sliceName].selectedProductId;

const groupedEvaluationsSelector = createSelector(
  selectedProductIdSelector,
  selectors.selectAll,
  (selectedProductId, evaluations): IGroupedEvaluation[] => {
    const filteredData = evaluations.filter(
      (i) => i.product.id === selectedProductId
    );
    const byProductVersion = groupBy(filteredData, 'productVersion');
    const readyData = keys(byProductVersion).map((productVersion) => {
      const entities = byProductVersion[productVersion];
      const scoreSum = sumBy(entities, 'score');
      const scoreAverage = ceil(scoreSum / entities.length, 1);

      return {
        productVersion,
        id: nanoid(5),
        scoreAverage,
        entities,
      };
    });

    return readyData.sort((a, b) =>
      compareVersions(a.productVersion, b.productVersion)
    );
  }
);

export const evaluationsSelectors = {
  ...selectors,
  selectById: (id: EvaluationDto['id']) => (state: RootState) =>
    selectors.selectById(state, id),
  selectedProductVersion: (state: RootState) =>
    state[sliceName].selectedProductVersion,
  selectedProductId: selectedProductIdSelector,
  selectedFeatureId: (state: RootState) => state[sliceName].selectedFeatureId,
  groupedEvaluations: groupedEvaluationsSelector,
  isFirstLoad: (state: RootState) => state[sliceName].isFirstLoad,
};

export const evaluationsActions = slice.actions;

export default slice.reducer;
