import { browserHistory } from 'lib/history';
import { noop } from 'lodash';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { JWTokenStorage } from 'web-core/lib/utils/JWTAuth/JWToken';
import { LoginPage } from '../components/common/LoginPage/LoginPage';
import { Downloads } from '../components/Downloads';
import { FilteredIps } from '../components/FilteredIps';
import { getModalComponent } from '../components/modals';
import { ModalWrapper } from '../components/modals/ModalWrapper';
import { OperationSystems } from '../components/OperationSystems';
import { ProductReleases } from '../components/ProductReleases';
import { Reviews } from '../components/Reviews';
import { commonSelectors } from '../store/commonSlice';
import { errorsSelectors } from '../store/errrosSlice';
import { IThemes } from '../types';
import {
  browserRoutes,
  localStorageKeys,
  mediaQueries,
} from '../utils/constants';
import useMediaQuery from '../utils/useMediaQuery';
import { changeTheme, getTheme, lazyWithRetry } from '../utils/utils';
import { Core } from './Core';
import { useTypedSelector } from './rootReducer';

const Features = lazyWithRetry(import('../components/Features/'));
const UserActions = lazyWithRetry(import('../components/UserActions'));
const Evaluations = lazyWithRetry(import('../components/Evaluations'));

const currentTheme = getTheme();
localStorage.setItem(localStorageKeys.theme, currentTheme);

changeTheme(currentTheme);

export const ThemeContext = React.createContext({
  theme: currentTheme,
  toggleTheme: noop,
});

export const BreakpointsContext = React.createContext({
  isMd: false,
  isSm: false,
});

type AppProps = {};
const App: FunctionComponent<AppProps> = () => {
  const [theme, setTheme] = useState(currentTheme);
  const { type: modalType } = useTypedSelector(commonSelectors.modalState);
  const complexError = useTypedSelector(errorsSelectors.common);
  // const dispatch = useDispatch();
  const isMd = useMediaQuery(mediaQueries.md);
  const isSm = useMediaQuery(mediaQueries.sm);

  const breakpointValue = useMemo(
    () => ({
      isMd,
      isSm,
    }),
    [isMd, isSm]
  );

  const themeValue = useMemo(() => {
    return {
      theme,
      toggleTheme: (newTheme: IThemes) => {
        localStorage.setItem(localStorageKeys.theme, newTheme);
        setTheme(newTheme);
        changeTheme(newTheme);
      },
    };
  }, [theme, setTheme]);

  const renderModal = (type) => {
    if (type === null) return null;
    const Component = getModalComponent(type);
    return <ModalWrapper WrappedComponent={Component} />;
  };

  const { error, payload } = complexError;
  // const title = payload?.title || error?.name;
  const message = payload?.detail || error?.message;

  useEffect(() => {
    toast.error(message);
  }, [error, message]);

  /* const onErrorDismiss = () => {
    dispatch(errorsActions.resetError({ type: errorTypes.common }));
  }; */

  if (!JWTokenStorage.isTokenDefined())
    browserHistory.replace(browserRoutes.login);

  return (
    // fallback for translations download
    <React.Suspense fallback={<></>}>
      <Core themeContext={ThemeContext}>
        <ThemeContext.Provider value={themeValue}>
          <BreakpointsContext.Provider value={breakpointValue}>
            <div className="h-100 w-100 d-flex overflow-auto">
              <Switch>
                <Route path={browserRoutes.login}>
                  <LoginPage />
                </Route>
                <Route path={browserRoutes.features}>
                  <Features />
                </Route>
                <Route path={browserRoutes.evaluations}>
                  <Evaluations />
                </Route>
                <Route path={browserRoutes.reviews}>
                  <Reviews />
                </Route>
                <Route path={browserRoutes.productReleases}>
                  <ProductReleases />
                </Route>
                <Route path={browserRoutes.downloads}>
                  <Downloads />
                </Route>
                <Route path={browserRoutes.operationSystems}>
                  <OperationSystems />
                </Route>
                <Route path={browserRoutes.userActions}>
                  <UserActions />
                </Route>
                <Route path={browserRoutes.filteredIps}>
                  <FilteredIps />
                </Route>
                <Route>
                  <Redirect to={browserRoutes.features} />
                </Route>
              </Switch>
            </div>

            {renderModal(modalType)}

            {/*error && (
              <div className="fixed-bottom">
                <PopupMessage
                  title={title}
                  message={message}
                  onDismiss={onErrorDismiss}
                />
              </div>
            )*/}
          </BreakpointsContext.Provider>
        </ThemeContext.Provider>

        <ToastContainer
          autoClose={10000}
          position={'bottom-right'}
          closeOnClick={false}
          draggable={isSm}
          transition={Slide}
          newestOnTop
        />
      </Core>
    </React.Suspense>
  );
};

export default App;
