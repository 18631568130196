import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BlockUI, TableComponent } from 'web-core/lib/web-components';
import { IColumnForTableComponent } from 'web-core/lib/web-components/TableComponent/TableComponent';
import { useTypedSelector } from '../../app/rootReducer';
import { fetchingStatesSelectors } from '../../store/fetchingStatesSlice';
import {
  filteredIpsActions,
  filteredIpsSelectors,
} from '../../store/filteredIpsSlice';
import { filtersSelectors } from '../../store/tables/filtersSlice';
import { fetchingStatuses } from '../../utils/constants';

interface IFilteredIp {
  id: number;
  ip: string;
  type: string;
}

export const FilteredIpsTable = () => {
  const dispatch = useDispatch();
  const filteredIps = useTypedSelector(filtersSelectors.filteredFilteredIps);
  const filteredIpsFetchingState = useTypedSelector(
    fetchingStatesSelectors.filteredIps
  );
  const filteredIpTypesFetchingState = useTypedSelector(
    fetchingStatesSelectors.filteredIpTypes
  );
  const selectedFilteredIpId = useTypedSelector(
    filteredIpsSelectors.selectedFilteredIpId
  );

  useEffect(() => {
    if (
      selectedFilteredIpId &&
      !filteredIps.find((filteredIp) => filteredIp.id === selectedFilteredIpId)
    )
      dispatch(filteredIpsActions.resetFilteredIp());
  }, [dispatch, filteredIps, selectedFilteredIpId]);

  const handleSelectRow = (id) => {
    dispatch(filteredIpsActions.selectFilteredIp({ id }));
  };

  const isFetching =
    filteredIpsFetchingState === fetchingStatuses.pending ||
    filteredIpTypesFetchingState === fetchingStatuses.pending;

  const [columnsState, setColumnsState] = useState([
    {
      isResizable: true,
      isSortable: true,
      style: {
        maxWidth: 150,
      },
      dataField: 'ip',
    },
    {
      isSortable: true,
      dataField: 'type',
    },
  ] as IColumnForTableComponent<IFilteredIp>[]);

  return (
    <div className="w-100 mb-5 overflow-auto z-0">
      <div className="z-0">
        <div>
          <BlockUI isBlocked={isFetching}>
            <TableComponent<IFilteredIp>
              tableName="filteredIps"
              columnsState={columnsState}
              setColumnsState={setColumnsState}
              selectedRow={selectedFilteredIpId}
              data={filteredIps}
              onSelectRow={handleSelectRow}
              keyField="id"
            />
          </BlockUI>
        </div>
      </div>
    </div>
  );
};
