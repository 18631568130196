import { Range } from 'rc-slider';
import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { useThemeChangeTracker } from '../../utils/utils';

interface IVersionsRange {
  isRange: boolean;
  handleDoubleRangeChange: Function;
  productVersions;
  setFilterCanceled: Function;
  filterCanceled;
}

export const VersionsRange: React.FC<IVersionsRange> = ({
  isRange,
  handleDoubleRangeChange,
  productVersions,
  filterCanceled,
  setFilterCanceled,
}) => {
  const theme = useThemeChangeTracker();

  const grayColor = theme === 'theme-dark' ? '#3E4146' : '#F0F1F3';
  let rangeColor = theme === 'theme-dark' ? '#689EE8' : '#256BCD';
  rangeColor = isRange ? rangeColor : grayColor;

  const productVersionsAmount = productVersions.length - 1;

  const [rangeValues, setRangeValues] = useState([0, productVersionsAmount]);

  useEffect(() => {
    setRangeValues([0, productVersionsAmount]);
  }, [productVersionsAmount, setRangeValues]);

  useEffect(() => {
    if (filterCanceled) {
      handleDoubleRangeChange(0, productVersionsAmount);
      setRangeValues([0, productVersionsAmount]);
      setFilterCanceled(false);
    }
  }, [
    filterCanceled,
    handleDoubleRangeChange,
    setFilterCanceled,
    productVersionsAmount,
  ]);

  const marks = {};
  productVersions.forEach((version, i) => {
    marks[i] = version;
  });

  return (
    <div>
      <div className="mx-2">
        <Range
          defaultValue={[0, productVersionsAmount]}
          value={rangeValues}
          step={1}
          disabled={!isRange}
          allowCross={false}
          min={0}
          max={productVersionsAmount}
          marks={marks}
          onChange={(values) => {
            handleDoubleRangeChange(values[0], values[1]);
            setRangeValues(values);
          }}
          dotStyle={{
            color: grayColor,
            backgroundColor: grayColor,
            border: `1px solid ${grayColor}`,
          }}
          activeDotStyle={{
            backgroundColor: rangeColor,
            color: rangeColor,
            border: `1px solid ${rangeColor}`,
          }}
          railStyle={{ backgroundColor: grayColor }}
          trackStyle={[{ backgroundColor: rangeColor }]}
          handleStyle={Array(2).fill({
            backgroundColor: rangeColor,
            border: `1px solid ${rangeColor}`,
            boxShadow: 'unset',
          })}
        />
      </div>
      <div className="d-flex flex-row justify-content-between mt-5">
        <Input
          disabled={!isRange}
          onChange={(e) => {
            const newLeftValue = Number(e.target.value);
            setRangeValues((prev) => {
              return [newLeftValue, prev[1]];
            });
            handleDoubleRangeChange(newLeftValue, rangeValues[1]);
          }}
          type="select"
          value={rangeValues[0]}
          className="form-control-sm w-25"
        >
          {productVersions.map((productVersion) => {
            const productVersionNumberValue =
              Number(productVersion.split('.')[1]) - 1;
            return (
              productVersionNumberValue <= rangeValues[1] && (
                <option
                  key={`left_${productVersion}`}
                  value={productVersionNumberValue}
                >
                  {productVersion}
                </option>
              )
            );
          })}
        </Input>
        <Input
          disabled={!isRange}
          type="select"
          value={rangeValues[1]}
          className="form-control-sm w-25"
          onChange={(e) => {
            const newRightValue = Number(e.target.value);
            setRangeValues((prev) => {
              return [prev[0], newRightValue];
            });
            handleDoubleRangeChange(rangeValues[0], newRightValue);
          }}
        >
          {productVersions.map((productVersion) => {
            const productVersionNumberValue =
              Number(productVersion.split('.')[1]) - 1;
            return (
              productVersionNumberValue >= rangeValues[0] && (
                <option
                  key={`right_${productVersion}`}
                  value={productVersionNumberValue}
                >
                  {productVersion}
                </option>
              )
            );
          })}
        </Input>
      </div>
    </div>
  );
};
