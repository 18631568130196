import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setRememberMe } from 'web-core/lib/utils/JWTAuth/JWToken';
import { LoginForm } from 'web-core/lib/web-components/LoginForm/LoginForm';
import { useTypedSelector } from '../../../app/rootReducer';
import Logo from '../../../assets/logo.png';
import { login } from '../../../store/authSlice';
import { errorsActions, errorsSelectors } from '../../../store/errrosSlice';
import { IBrowserRoutes } from '../../../types';
import './style.scss';

type LoginPageProps = {};
export const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const complexError = useTypedSelector(errorsSelectors.auth);

  const locationState = history.location.state as { prevPage: IBrowserRoutes };

  const { error, payload } = complexError;
  // const title = payload?.title || error?.name;
  const message = payload?.detail || error?.message;

  /*const onErrorDismiss = useCallback(() => {
    dispatch(errorsActions.resetError({ type: errorTypes.auth }));
  }, [dispatch]);*/

  useEffect(() => {
    toast.dismiss();
    toast.error(message);
  }, [error, message]);

  return (
    <>
      <LoginForm
        logo={Logo}
        onSubmitHandler={async (values) => {
          const valuesToSend = {
            email: values.login,
            password: values.password,
          };

          const resultAction: any = await dispatch(login(valuesToSend));

          // @ts-ignore
          if (login.fulfilled.match(resultAction)) {
            setRememberMe(values.login);
            dispatch(errorsActions.resetAll());
            const url = locationState?.prevPage || '/';
            history.replace(url);
          }
        }}
        theme={'theme-dark'}
        title={<>{t('loginPage.telemetry')}</>}
      ></LoginForm>
    </>
  );
};
