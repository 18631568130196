import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const backendOptions = {
  loadPath: (languages, namespaces) => {
    const language = languages[0].split('-')[0];
    const namespace = namespaces[0];
    const url = process.env.PUBLIC_URL;

    return `${url}/locales/${language}/${namespace}.json`;
  },
};

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en', // use en if detected lng is not available
    load: 'all', // for resolve en-US, en-GB and etc languages
    saveMissing: true, // send not translated keys to endpoint
    react: {
      // Set to true if you like to wait for loaded in every translated hoc
      wait: true,
    },
    backend: backendOptions,
  });

export default i18n;
