import cn from 'classnames';
import { formatISO, sub } from 'date-fns';
import { toNumber } from 'lodash';
import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DayPickerProps } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useTranslation } from 'react-i18next';
import { localizedFormat } from '../../../lib/dateFns';
import { dateRangeSeparator } from '../../../utils/constants';
import { parseDateRange } from '../../../utils/utils';
import './style.scss';

const getDefaultValue = parseDateRange;

type DatePickerRangeProps = {
  value?: string;
  onChange: (isoDate: string) => void;
  minDate?: Date;
  maxDate?: Date;
  withYTranslate?: boolean;
  inputClassnames?: string;
  inputStyles?: CSSProperties;
};

const captionElement = (
  localeProps,
  handleChange,
  selectedRange,
  toOrFrom: 'to' | 'from'
) => ({ date, localeUtils }) => {
  const years = [];
  for (let i = 2020; i <= new Date().getFullYear(); i += 1) {
    years.push(i);
  }

  const checkIfDisabled = (date, toDate, fromDate) => {
    if (toOrFrom === 'from') return date > toDate;
    return date <= fromDate;
  };

  return (
    <form className="DayPicker-Caption">
      <div className="d-flex flex-row justify-content-between mb-2">
        <select
          name="month"
          style={{ marginLeft: -10, marginRight: 10, width: 80, fontSize: 12 }}
          onChange={(e) => {
            const newDate = new Date(selectedRange[toOrFrom]);
            newDate.setMonth(Number(e.target.value));
            handleChange(newDate);
          }}
          value={date.getMonth()}
        >
          {localeUtils.getMonths().map((month, i) => {
            const d = new Date(selectedRange[toOrFrom]);
            d.setMonth(i);

            return (
              <option
                disabled={checkIfDisabled(
                  d,
                  selectedRange.to,
                  selectedRange.from
                )}
                key={month}
                value={i}
              >
                {localeProps.months[i]}
              </option>
            );
          })}
        </select>
        <div className="d-inline-block" style={{ marginRight: 40 }}>
          <select
            style={{ width: 60, fontSize: 12 }}
            onChange={(e) => {
              const newDate = new Date(selectedRange[toOrFrom]);
              newDate.setFullYear(Number(e.target.value));

              handleChange(newDate);
            }}
            value={date.getFullYear()}
          >
            {years.map((year) => {
              const d = new Date(selectedRange[toOrFrom]);
              d.setFullYear(Number(year));

              return (
                <option
                  disabled={checkIfDisabled(
                    d,
                    selectedRange.to,
                    selectedRange.from
                  )}
                  key={year}
                  value={year}
                >
                  {year}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </form>
  );
};

export const DatePickerRange: FunctionComponent<DatePickerRangeProps> = (
  props
) => {
  const {
    value,
    onChange,
    minDate,
    maxDate,
    withYTranslate,
    inputClassnames,
    inputStyles,
  } = props;
  const defaultValue = getDefaultValue(value);

  const [selectedRange, selectRange] = useState(defaultValue);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (value === '') selectRange(getDefaultValue(value));
    else selectRange(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selectRange]);

  const formatDate = (date) => {
    return localizedFormat(date, i18n.language);
  };

  const formatRange = (range) => {
    return `${range.from}${dateRangeSeparator}${range.to}`;
  };

  const handleRangeChange = (from, to) => {
    if (!to) {
      const today = new Date();
      selectRange({ from, to: today });

      const isoFrom = formatISO(new Date(from), { representation: 'date' });
      const isoToday = formatISO(today, { representation: 'date' });
      onChange(
        formatRange({
          from: isoFrom,
          to: isoToday,
        })
      );
    } else if (!from) {
      const monthAgoFromTo = sub(new Date(to), { months: 1 });
      selectRange({ from: monthAgoFromTo, to });

      const isoMonthAgo = formatISO(new Date(monthAgoFromTo), {
        representation: 'date',
      });
      const isoTo = formatISO(new Date(to), { representation: 'date' });
      onChange(
        formatRange({
          from: isoMonthAgo,
          to: isoTo,
        })
      );
    } else {
      selectRange({ from, to });

      const isoFrom = formatISO(new Date(from), { representation: 'date' });
      const isoTo = formatISO(new Date(to), { representation: 'date' });
      onChange(
        formatRange({
          from: isoFrom,
          to: isoTo,
        })
      );
    }
  };

  const inputClasses = cn(
    `form-control w-100 ${inputClassnames ? inputClassnames : ''}`
  );

  const component = (ref) => (props) => (
    <div>
      <input
        type="text"
        ref={ref}
        className={inputClasses}
        {...props}
        style={{ ...inputStyles }}
      />
    </div>
  );

  const classes = {
    container: 'DayPickerInput',
    overlay: `DayPickerInput-Overlay ${
      withYTranslate ? 'DayPickerInput-Overlay_withTranslation' : ''
    } bg-body DayPickerInput-Overlay_to-left`,
    overlayWrapper: 'DayPickerInput-OverlayWrapper',
  };

  const handleFromChange = (from) => {
    handleRangeChange(from, selectedRange.to);
  };

  const handleToChange = (to) => {
    handleRangeChange(selectedRange.from, to);
  };

  const { from, to } = selectedRange;
  const modifiers = { start: from, end: to };

  const localeProps = {
    locale: i18n.language,
    months: t('dayPicker.months', { returnObjects: true }),
    weekdaysLong: t('dayPicker.weekdaysLong', { returnObjects: true }),
    weekdaysShort: t('dayPicker.weekdaysShort', { returnObjects: true }),
    firstDayOfWeek: toNumber(t('dayPicker.firstDayOfWeek')),
    labels: t('dayPicker.labels', { returnObjects: true }),
  } as Partial<DayPickerProps>;

  const getDisabledDays = (isLeftInput) => {
    if (isLeftInput) {
      const disabledDays = { after: to, before: new Date(2020, 3, 5) };
      return minDate ? { ...disabledDays, before: minDate } : disabledDays;
    } else {
      const disabledDays = { before: from };
      return maxDate ? { ...disabledDays, after: maxDate } : disabledDays;
    }
  };

  const inputRefs = useRef({ from: null, to: null });
  const datepickerRefs = useRef({ from: null, to: null });

  useEffect(() => {
    const handler = (e) => {
      const leftDayPicker = document.querySelector('.DayPickerInput-Left');
      const rightDayPicker = document.querySelector('.DayPickerInput-Right');
      if (leftDayPicker) {
        if (!leftDayPicker.contains(e.target)) {
          datepickerRefs.current?.from?.hideAfterDayClick &&
            datepickerRefs.current.from.hideAfterDayClick();
        }
      }
      if (rightDayPicker) {
        if (!rightDayPicker.contains(e.target)) {
          datepickerRefs.current?.to?.hideAfterDayClick &&
            datepickerRefs.current.to.hideAfterDayClick();
        }
      }
    };
    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  return (
    <div className="d-flex align-items-center">
      <DayPickerInput
        ref={(el) => (datepickerRefs.current.from = el)}
        classNames={{
          ...classes,
          container: `${classes.container} DayPickerInput-Left`,
          overlay: `DayPickerInput-Overlay DayPickerInput-Overlay_to-top bg-body ${
            withYTranslate ? 'DayPickerInput-Overlay_withTranslation' : ''
          }`,
        }}
        value={from}
        placeholder={t('dayPicker.from')}
        formatDate={formatDate}
        component={component((el) => {
          inputRefs.current.from = el;
        })}
        dayPickerProps={{
          captionElement: captionElement(
            localeProps,
            handleFromChange,
            selectedRange,
            'from'
          ),
          className: 'mt-2 border border-gray',
          selectedDays: [from, { from, to }],
          disabledDays: getDisabledDays(true),
          toMonth: to,
          month: from,
          modifiers,
          ...localeProps,
        }}
        onDayChange={handleFromChange}
      />
      <div className="mx-3">—</div>
      <DayPickerInput
        ref={(el) => (datepickerRefs.current.to = el)}
        classNames={{
          ...classes,
          container: `${classes.container} DayPickerInput-Right`,
        }}
        value={to}
        placeholder={t('dayPicker.to')}
        formatDate={formatDate}
        component={component((el) => {
          inputRefs.current.to = el;
        })}
        dayPickerProps={{
          captionElement: captionElement(
            localeProps,
            handleToChange,
            selectedRange,
            'to'
          ),
          className: 'mt-2 border border-gray',
          selectedDays: [to, { from, to }],
          disabledDays: getDisabledDays(false),
          modifiers,
          month: to,
          fromMonth: from,
          ...localeProps,
        }}
        onDayChange={handleToChange}
      />
    </div>
  );
};

DatePickerRange.defaultProps = {
  value: '',
};
