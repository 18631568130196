import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { featuresApi } from '../api';
import { RootState } from '../app/rootReducer';
import { FeatureDto, FeatureWithEvaluationsCountDto } from '../types/dto';
import { asyncThunkHandler } from '../utils/sliceHelpers';

const sliceName = 'features';

const adapter = createEntityAdapter<FeatureDto>({
  sortComparer: (a, b) => a.id - b.id,
});

interface IFeaturesState {
  selectedFeatureId: FeatureDto['id'] | null;
  featuresWithEvaluationsCount: FeatureWithEvaluationsCountDto[];
}

const initialState: IFeaturesState = {
  selectedFeatureId: null,
  featuresWithEvaluationsCount: [],
};

export const fetchFeatures = createAsyncThunk<FeatureDto[], undefined>(
  `${sliceName}/fetchAll`,
  asyncThunkHandler(featuresApi.getAll)
);

export const fetchFeatureById = createAsyncThunk<
  FeatureDto,
  { id: FeatureDto['id'] }
>(`${sliceName}/fetchById`, asyncThunkHandler(featuresApi.getById));

export const createFeature = createAsyncThunk<FeatureDto, Partial<FeatureDto>>(
  `${sliceName}/create`,
  asyncThunkHandler(featuresApi.create)
);

export const updateFeature = createAsyncThunk<FeatureDto, Partial<FeatureDto>>(
  `${sliceName}/update`,
  asyncThunkHandler(featuresApi.update)
);

export const fetchFeaturesWithEvaluationsCount = createAsyncThunk<
  FeatureWithEvaluationsCountDto[],
  undefined
>(
  `${sliceName}/fetchAllWithEvaluationCount`,
  asyncThunkHandler(featuresApi.fetchFeaturesWithEvaluationsCount, (data) =>
    orderBy(data, 'evaluationCount', 'desc')
  )
);

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(initialState),
  reducers: {
    selectFeature: (
      state,
      { payload }: PayloadAction<{ id: null | FeatureDto['id'] }>
    ) => {
      const { id } = payload;
      state.selectedFeatureId = id;
    },
    resetFeature: (state) => {
      state.selectedFeatureId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatures.fulfilled, adapter.setAll)
      .addCase(fetchFeatureById.fulfilled, adapter.upsertOne)
      .addCase(createFeature.fulfilled, adapter.addOne)
      .addCase(updateFeature.fulfilled, adapter.upsertOne)
      .addCase(fetchFeaturesWithEvaluationsCount.fulfilled, (state, action) => {
        state.featuresWithEvaluationsCount = action.payload;
      });
  },
});

const selectors = adapter.getSelectors((state: RootState) => state[sliceName]);

export const featuresSelectors = {
  ...selectors,
  selectById: (id: FeatureDto['id']) => (state: RootState) =>
    selectors.selectById(state, id),
  selectedFeatureId: (state: RootState) => state[sliceName].selectedFeatureId,
  selectFeaturesWithEvaluationsCount: (state: RootState) =>
    state[sliceName].featuresWithEvaluationsCount,
};

export const featuresActions = slice.actions;

export default slice.reducer;
