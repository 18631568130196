import { ProductDto, ProductReleaseDto } from '../types/dto';
import { requestWithAuth } from './config';

const resource = '/product_releases';

export const getAll = () => {
  return requestWithAuth.get(resource);
};

// export const getById = (data: { id: number }) => {
//   return requestWithAuth.get(`${resource}/${data.id}`);
// };

export const create = (data: {
  productId: ProductDto['id'];
  version: ProductReleaseDto['version'];
  date: ProductReleaseDto['date'];
  productReleaseId: ProductReleaseDto['id'];
}) => {
  const { productId, productReleaseId, ...rest } = data;
  const newData = {
    ...rest,
    product_id: productId,
    release_id: productReleaseId,
  };
  return requestWithAuth.post(resource, newData);
};

export const update = (data: {
  productId: ProductDto['id'];
  version: ProductReleaseDto['version'];
  date: ProductReleaseDto['date'];
  productReleaseId: ProductReleaseDto['id'];
}) => {
  const { productId, productReleaseId, ...rest } = data;
  const newData = {
    ...rest,
    product_id: productId,
  };
  return requestWithAuth.put(`${resource}/${productReleaseId}`, newData);
};
