import { date, number, object, string } from 'yup';

export const getProductReleaseValidationSchema = (t) =>
  object().shape({
    version: string()
      .required(t('validation.required'))
      .trim()
      .matches(/^(\d+\.)(\d+\.)(\d+)$/, {
        message: t('validation.match', { match: '1.12.123' }),
      }),
    productId: number().required(t('validation.required')).integer(),
    date: date().required(t('validation.required')),
  });

export const getFilteredIpValidationSchema = (t) =>
  object().shape({
    ip: string()
      .required(t('validation.required'))
      .trim()
      .when('type', {
        is: 'full',
        then: string().matches(
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
          {
            message: t('validation.match', { match: '192.168.0.1' }),
          }
        ),
        otherwise: string().matches(
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(.|.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){0,3}$/,
          {
            message: t('validation.match', { match: '192[.168[...][...]]' }),
          }
        ),
      }),
    type: string(),
  });
