import { addDays, format } from 'date-fns';
import { EChartView } from 'types/downloads';

export const chartViewOptions = [
  EChartView.linear,
  EChartView.monotone,
  EChartView.step,
  EChartView.basis,
];

export const fillEmptyDatesWithZeros = (
  data,
  dataKeys,
  startDate: { month; day }
) => {
  let dataWithExtraDates = [];

  if (data.length > 1) {
    // *** Заполнить нулями всё, что в промежутке от выбранной начальной даты до первой даты в массиве
    const [firstDataEntry] = data;
    const firstDataRecord = new Date(firstDataEntry.date);
    const selectedStartDate = new Date(
      firstDataRecord.getFullYear(),
      startDate.month,
      startDate.day
    );
    if (selectedStartDate < firstDataRecord) {
      for (
        let d = selectedStartDate;
        d < firstDataRecord;
        d.setDate(d.getDate() + 1)
      ) {
        const newRecord = {
          ...Object.fromEntries(
            dataKeys.map((key) => {
              return [key, 0];
            })
          ),
          date: format(d, 'MM/dd/yyyy'),
        };

        dataWithExtraDates.push(newRecord);
      }
    }
    // ***

    // *** Заполнить нулями всё, что между двумя датами, которые идут не по порядку в массиве
    dataWithExtraDates.push(data[0]);
    for (let i = 1; i < data.length; i += 1) {
      const dataRecord = data[i];
      const previousDataRecord = data[i - 1];

      const currentDate = new Date(dataRecord.date);
      const previousDate = new Date(previousDataRecord.date);
      if (addDays(previousDate, 1).getDate() !== currentDate.getDate()) {
        for (
          let d = addDays(previousDate, 1);
          d < currentDate;
          d.setDate(d.getDate() + 1)
        ) {
          const newRecord = {
            ...Object.fromEntries(
              dataKeys.map((key) => {
                return [key, 0];
              })
            ),
            date: format(d, 'MM/dd/yyyy'),
          };

          dataWithExtraDates.push(newRecord);
        }
        dataWithExtraDates.push(dataRecord);
      } else {
        dataWithExtraDates.push(dataRecord);
      }
    }
    // ***
  } else dataWithExtraDates = data;
  return dataWithExtraDates;
};
