import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/rootReducer';
import { IError, IErrorTypes } from '../types';
import { errorTypes, initialError } from '../utils/constants';
import { login, logout } from './authSlice';
import { fetchFeatureEvaluations } from './evaluationsSlice';
import {
  fetchFeatureById,
  fetchFeatures,
  fetchFeaturesWithEvaluationsCount,
  createFeature,
  updateFeature,
} from './featuresSlice';
import {
  createProductRelease,
  fetchProductReleases,
  updateProductRelease,
} from './productReleasesSlice';
import { fetchProducts } from './productsSlice';

const sliceName = 'errors';

interface IErrorsState {
  auth: IError;
  common: IError;
}

const initialState: IErrorsState = {
  auth: initialError,
  common: initialError,
};

const ignoreAuthErrorTypes: IErrorTypes[] = [errorTypes.common];

const handler = (type: IErrorTypes) => (state, action) => {
  const { error, payload } = action;

  if (ignoreAuthErrorTypes.includes(type) && payload?.status === 401) {
    state[type] = initialError;
  } else {
    state[type] = {
      error,
      payload,
    } as IError;
  }
};

const errors = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetError: (state, { payload }: PayloadAction<{ type: IErrorTypes }>) => {
      const { type } = payload;
      state[type] = initialError;
    },
    resetAll: () => initialState,
  },
  extraReducers: (builder) => {
    //auth
    builder
      .addCase(login.rejected, handler(errorTypes.auth))
      .addCase(logout.rejected, handler(errorTypes.auth));

    //common
    builder
      .addCase(createFeature.rejected, handler(errorTypes.common))
      .addCase(updateFeature.rejected, handler(errorTypes.common))
      .addCase(fetchFeatureById.rejected, handler(errorTypes.common))
      .addCase(fetchFeatures.rejected, handler(errorTypes.common))
      .addCase(fetchFeatureEvaluations.rejected, handler(errorTypes.common))
      .addCase(
        fetchFeaturesWithEvaluationsCount.rejected,
        handler(errorTypes.common)
      );

    builder.addCase(fetchProducts.rejected, handler(errorTypes.common));

    builder
      .addCase(fetchProductReleases.rejected, handler(errorTypes.common))
      .addCase(createProductRelease.rejected, handler(errorTypes.common))
      .addCase(updateProductRelease.rejected, handler(errorTypes.common));
  },
});

export const errorsSelectors = {
  auth: (state: RootState) => state[sliceName].auth,
  common: (state: RootState) => state[sliceName].common,
};

export const errorsActions = errors.actions;

export default errors.reducer;
