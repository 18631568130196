import { downloadsApi } from 'api';
import { format, isBefore, parseISO } from 'date-fns';
import { isAfter, subMinutes } from 'date-fns/esm';
import downloadFile from 'downloadjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { CustomInput } from 'reactstrap';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  EChartView,
  EDownloadsRouterPages,
  ESelectedProduct,
  ESelectedType,
} from 'types/downloads';
import { BlockUI, IconButton } from 'web-core/lib/web-components';
import { PageHeader } from 'web-core/lib/web-components/PageHeader/PageHeader';
import { download } from 'web-icons/svgIcons/download';
import { useTypedSelector } from '../../app/rootReducer';
import { localizedFormat } from '../../lib/dateFns';
import {
  downloadsActions,
  downloadsSelectors,
  fetchAppDownloads,
} from '../../store/downloads/downloadsSlice';
import { fetchingStatesSelectors } from '../../store/fetchingStatesSlice';
import {
  fetchProductReleases,
  productReleasesSelectors,
} from '../../store/productReleasesSlice';
import { dateFormats, fetchingStatuses } from '../../utils/constants';
import { DatePickerRange } from '../common/DatePicker/DatePickerRange';
import { ChartSettings } from './CompareChart/ChartSettings';
import { CompareChart } from './CompareChart/CompareChart';
import { useRouterButtonsBar } from './RouterButtonsBar';
import { chartViewOptions, fillEmptyDatesWithZeros } from './utils';

const extractNameFunctionsByType = {
  product: (value) => value.name,
};

const periodMapping = {
  day: 'day',
  month: 'month',
};

const ReferenceLabel = (props) => {
  const { value, textAnchor, viewBox, dy } = props;
  const x = viewBox.width + viewBox.x;
  const y = viewBox.y + dy * 24;
  return (
    <text x={x} y={y + 24} textAnchor={textAnchor} fill="currentcolor">
      {value}
    </text>
  );
};

export const Downloads = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const downloadsRouterButtonsBar = useRouterButtonsBar({
    prefix: '/downloads',
  });

  const [showReleases, setShowReleases] = useState(true);

  const isOnDownloadsPath = useLocation().pathname === '/downloads/';

  const parsedDownloads = useTypedSelector(
    downloadsSelectors.selectParsedDownloads
  );

  const data = useTypedSelector(downloadsSelectors.selectFilteredByType);
  const {
    selectedType,
    selectedRange,
    selectedProduct,
    selectedPeriod,
    selectedChartView,
    minDate,
    maxDate,
  } = useTypedSelector(downloadsSelectors.selectState);

  const selectedRangeSplit = selectedRange.split('&&');
  const [rangeFrom] = selectedRangeSplit;

  const productReleases = useTypedSelector(productReleasesSelectors.selectAll);

  const downloadsFetchingState = useTypedSelector(
    fetchingStatesSelectors.downloads
  );
  const productReleasesFetchingState = useTypedSelector(
    fetchingStatesSelectors.productReleases
  );
  const appDownloadsFetchingState = useTypedSelector(
    fetchingStatesSelectors.appDownloads
  );

  const isOneProductSelected = useCallback(
    () => selectedProduct !== 'all' && selectedType === 'product',
    [selectedProduct, selectedType]
  );

  const dataKeys: string[] = parsedDownloads[selectedType]
    .map((v: any) => v.name)
    .filter((v) => {
      if (!v) return false;
      if (selectedType === ESelectedType.product) {
        if (selectedProduct === ESelectedProduct.all) return true;
        return v === selectedProduct;
      }
      return true;
    });

  const dataForDiagram = useMemo(() => {
    const groupedByStatisticId = _.groupBy(
      _.keys(data).reduce((acc, i) => [...acc, ...data[i]], []),
      'downloadStatistics'
    );

    const dataWithDate = _.keys(groupedByStatisticId).map((statisticsId) => {
      const statisticsData = groupedByStatisticId[statisticsId];
      const date = format(
        new Date(parsedDownloads.dateByInfoId[statisticsId]),
        dateFormats.numDate
      );
      const groupByObjectCount = statisticsData.reduce((acc, curr) => {
        const extract = extractNameFunctionsByType[selectedType];
        const name = extract ? extract(curr[selectedType]) : curr[selectedType];
        const newCount = _.has(acc, name) ? acc[name] + curr.count : curr.count;
        return { ...acc, [name]: newCount };
      }, {});
      return parsedDownloads[selectedType].map((obj) => {
        return {
          [obj.name]: groupByObjectCount[obj.name] || 0,
          date,
        };
      });
    });

    const flatted = dataWithDate.reduce((acc, curr) => {
      const flattedObject = curr.reduce((acc, i) => ({ ...acc, ...i }), {});
      return [...acc, flattedObject];
    }, []);

    let intermediateData = flatted;

    if (isOneProductSelected()) {
      intermediateData = flatted.map((dailyStatistic) => ({
        date: dailyStatistic.date,
        [selectedProduct]: dailyStatistic[selectedProduct],
      }));
    }

    const rangeFromDate = new Date(rangeFrom);

    intermediateData = fillEmptyDatesWithZeros(intermediateData, dataKeys, {
      day: rangeFromDate.getDate(),
      month: rangeFromDate.getMonth(),
    });

    if (selectedPeriod === periodMapping.month && isOnDownloadsPath) {
      const localizedSeparator = i18n.language === 'ru' ? '.' : '/';

      const groupedByMonth = _.groupBy(
        intermediateData,
        (item) =>
          item.date.slice(0, 2) + localizedSeparator + item.date.slice(6)
      );

      const flattedByMonths = _.keys(groupedByMonth).map((month) => {
        const collapsedData = groupedByMonth[month].reduce((acc, curr) => {
          _.keys(curr).forEach((key) => {
            if (key !== 'date' && key) acc[key] = (acc[key] || 0) + curr[key];
          });
          return acc;
        }, {});
        return { ...collapsedData, date: month };
      });

      return flattedByMonths;
    }

    return intermediateData;
  }, [
    isOnDownloadsPath,
    dataKeys,
    data,
    parsedDownloads,
    selectedType,
    selectedProduct,
    selectedPeriod,
    isOneProductSelected,
    i18n.language,
    rangeFrom,
  ]);

  const filteredDataByDate = useMemo(() => {
    return dataForDiagram.filter((entry) => {
      const range = selectedRange.split('&&');

      const timezoneOffset = new Date().getTimezoneOffset();
      const dateFrom = subMinutes(new Date(range[0]), -timezoneOffset);

      const dateTo = new Date(range[1]);

      let entryDateString = entry.date;

      if (selectedPeriod === 'month') {
        const entryDateSplit = entryDateString.split(
          i18n.language === 'ru' ? '.' : '/'
        );
        entryDateString = `${entryDateSplit[0]}/01/${entryDateSplit[1]}`;
      }

      const entryDate = new Date(entryDateString);
      return dateFrom <= entryDate && entryDate <= dateTo;
    });
  }, [dataForDiagram, i18n.language, selectedPeriod, selectedRange]);

  useEffect(() => {
    // dispatch(fetchDownloads());
    dispatch(fetchProductReleases());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchAppDownloads({
        params: {
          from: selectedRange.split('&&')[0],
          to: selectedRange.split('&&')[1],
          productIds: ['1', '2'],
        },
      })
    );
  }, [dispatch, selectedRange]);

  const handleDownloadCSVFile = () => {
    let productIds = ['1', '2'];
    if (selectedProduct === 'DICOM Viewer') productIds = ['1'];
    if (selectedProduct === 'DICOM Server') productIds = ['2'];
    downloadsApi
      .getAppDownloads({
        params: {
          from: selectedRange.split('&&')[0],
          to: selectedRange.split('&&')[1],
          productIds,
        },
        accept: 'text/csv',
      })
      .then((res) => {
        downloadFile(res.data, 'downloads.csv', 'text/csv');
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleDateChange = (value) => {
    dispatch(downloadsActions.selectRange({ range: value }));
  };

  const handleClickLegend = (lineData) => {
    dispatch(downloadsActions.selectObject({ object: lineData.dataKey }));
  };

  useEffect(() => {
    // if (downloadsRouterButtonsBar.page.id === EDownloadsRouterPages.Compare)
    // dispatch(downloadsActions.setToInitialState());
  }, [dispatch, downloadsRouterButtonsBar.page]);

  const isDownloadsLoading =
    (downloadsFetchingState === fetchingStatuses.pending &&
      productReleasesFetchingState === fetchingStatuses.pending) ||
    appDownloadsFetchingState === fetchingStatuses.pending;

  const dataForProduct = useMemo(() => {
    return productReleases.filter((productRelease) => {
      const date = productRelease.date;
      const productName = productRelease.product.name;

      const dateCondition =
        isAfter(parseISO(date), parseISO(minDate)) &&
        isBefore(parseISO(date), parseISO(maxDate));

      const productCondition =
        selectedProduct === 'all' || productName === selectedProduct;

      return dateCondition && productCondition;
    });
  }, [productReleases, minDate, maxDate, selectedProduct]);

  const getFormatForReferenceLine = useCallback(
    (productRelease, period) => {
      const lozalizedFormat = localizedFormat(
        new Date(productRelease.date),
        i18n.language
      );
      const localizedFormatForMonths = localizedFormat(
        new Date(productRelease.date),
        i18n.language,
        i18n.language === 'ru' ? ('MM.yyyy' as any) : ('MM/yyyy' as any)
      );
      if (period === periodMapping.month) return localizedFormatForMonths;
      return lozalizedFormat;
    },
    [i18n.language]
  );

  return (
    <div className="d-flex container flex-column overflow-auto pt-3 font-ag2">
      <PageHeader
        buttons={
          <>
            {downloadsRouterButtonsBar.page.id ===
              EDownloadsRouterPages.Browse && (
              <IconButton
                onClick={() => {
                  handleDownloadCSVFile();
                }}
                icon={download}
                text={t('downloads.downloadCSV')}
              ></IconButton>
            )}
          </>
        }
        title={t('downloads.header')}
      ></PageHeader>

      <div className="mt-3">{downloadsRouterButtonsBar.routerJSX}</div>

      {downloadsRouterButtonsBar.page.id === EDownloadsRouterPages.Browse ? (
        <>
          <div className="px-0 mb-4 mt-1">
            <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
              <div className="ml-7">
                <div className="pl-1 mb-2">
                  {t('downloads.chartButtons.range')}
                </div>
                <DatePickerRange
                  inputStyles={{ maxWidth: 96 }}
                  inputClassnames={''}
                  onChange={handleDateChange}
                  value={selectedRange}
                  // minDate={parseISO(minDate)}
                  // maxDate={parseISO(maxDate)}
                />
              </div>
              <div>
                <CustomInput
                  id="showReleasesCheckbox"
                  type="checkbox"
                  className="mt-4 ml-4"
                  checked={showReleases}
                  onChange={() => {
                    setShowReleases(!showReleases);
                  }}
                  label={
                    <div style={{ lineHeight: 1.75 }}>
                      {t('downloads.chartButtons.releases.header')}
                    </div>
                  }
                ></CustomInput>
              </div>

              <div className="ml-4 mt-4">
                <CustomInput
                  className="mr-2"
                  id={`showAsMonths_checkbox_2`}
                  type="checkbox"
                  checked={selectedPeriod === 'month'}
                  onChange={() => {
                    dispatch(
                      downloadsActions.selectPeriod({
                        period: selectedPeriod === 'day' ? 'month' : 'day',
                      })
                    );
                  }}
                  label={
                    <div style={{ lineHeight: 1.75 }}>
                      {t('downloads.monthly')}
                    </div>
                  }
                ></CustomInput>
              </div>

              <div className="ml-3 mt-4">
                <select
                  style={{ minWidth: 90 }}
                  onChange={(e) => {
                    dispatch(
                      downloadsActions.selectChartView({
                        view: e.target.value as EChartView,
                      })
                    );
                  }}
                  value={selectedChartView}
                >
                  {chartViewOptions.map((view) => (
                    <option value={view} key={view}>
                      {t(`downloads.chartViews.${view}`)}
                    </option>
                  ))}
                </select>
              </div>

              <div
                className="d-flex flex-grow-1 justify-content-end mr-7"
                style={{ marginTop: -34 }}
              >
                <ChartSettings></ChartSettings>
              </div>
            </div>

            <div className="d-flex"></div>
          </div>

          <BlockUI isBlocked={isDownloadsLoading}>
            <ResponsiveContainer width={'98%'} height="95%">
              <LineChart
                data={filteredDataByDate.map((dataRecord) => {
                  if (selectedPeriod === periodMapping.month) return dataRecord;
                  return {
                    ...dataRecord,
                    date: localizedFormat(
                      new Date(dataRecord.date),
                      i18n.language
                    ),
                  };
                })}
                margin={{
                  top: 20,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid />
                <XAxis
                  dataKey="date"
                  type="category"
                  allowDuplicatedCategory={false}
                />
                <YAxis type="number" />
                <Tooltip labelStyle={{ color: 'black' }} />
                <Legend
                  onClick={handleClickLegend}
                  wrapperStyle={{ cursor: 'pointer' }}
                />
                {parsedDownloads?.[selectedType].map((obj, i) => {
                  if (
                    !obj.name ||
                    (isOneProductSelected() && obj.name !== selectedProduct)
                  )
                    return null;
                  return (
                    <Line
                      dataKey={obj.name}
                      stroke={strokes[i]}
                      key={obj.name}
                      strokeWidth={3}
                      dot={false}
                      type={selectedChartView}
                    />
                  );
                })}
                {showReleases &&
                  dataForProduct.map((productRelease, index) => (
                    <ReferenceLine
                      key={productRelease.id}
                      x={getFormatForReferenceLine(
                        productRelease,
                        selectedPeriod
                      )}
                      label={
                        <ReferenceLabel
                          value={`${productRelease.product.name} ${productRelease.version}`}
                          dy={index % 3}
                        />
                      }
                      strokeDasharray="5 3"
                      stroke="grey"
                    />
                  ))}
              </LineChart>
            </ResponsiveContainer>
          </BlockUI>
        </>
      ) : (
        <div className="h-100 overflow-hidden">
          <CompareChart
            data={dataForDiagram}
            period={selectedPeriod}
            isDownloadsLoading={isDownloadsLoading}
          />
        </div>
      )}
    </div>
  );
};

const strokes = ['#318559', '#006C9C', '#963CBD'];
