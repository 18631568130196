import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';
import { useTypedSelector } from '../../app/rootReducer';
import { commonActions, commonSelectors } from '../../store/commonSlice';

type ModalWrapperProps = {
  WrappedComponent: any;
};
export const ModalWrapper: FunctionComponent<ModalWrapperProps> = (props) => {
  const dispatch = useDispatch();
  const { show, data } = useTypedSelector(commonSelectors.modalState);

  const hideModal = () => dispatch(commonActions.hideModal());

  const { WrappedComponent } = props;

  return (
    <Modal toggle={hideModal} isOpen={show}>
      <WrappedComponent data={data} hideModal={hideModal} />
    </Modal>
  );
};
