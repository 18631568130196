import React from 'react';

export const ViewerProLogo = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 8.5V28L0 32V14.5L6 8.5Z" fill="url(#paint0_linear)" />
    <path d="M18 26L24 32H0L6 26H18Z" fill="url(#paint1_linear)" />
    <path
      d="M6 0.5L24 18.5V32L18 28V20.5L6 8.5V0.5Z"
      fill="url(#paint2_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="3"
        y1="32"
        x2="3"
        y2="8.06266"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.25" stopColor="#7688D8" />
        <stop offset="1" stopColor="#5E93DB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="0"
        y1="29"
        x2="24"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.25" stopColor="#7688D8" />
        <stop offset="0.75" stopColor="#987AD5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="15"
        y1="32"
        x2="15"
        y2="0.0626214"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1875" stopColor="#987AD5" />
        <stop offset="1" stopColor="#C964CF" />
      </linearGradient>
    </defs>
  </svg>
);
