import { useFormik } from 'formik';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, CustomInput, Form, FormGroup, Row } from 'reactstrap';
import { useTypedSelector } from '../../app/rootReducer';
import {
  IReviewsState,
  reviewsActions,
  reviewsSelectors,
} from '../../store/reviewsSlice';
import { DatePickerRange } from '../common/DatePicker/DatePickerRange';
import { VersionsRange } from './ReviewVersionsRange';

const productNameMapping = {
  1: 'DICOM Viewer',
  2: 'DICOM Server',
};

export const ReviewFilterForm: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const filterValues = useTypedSelector(reviewsSelectors.filter);
  const initialValues = useTypedSelector(reviewsSelectors.initialValues);

  const [isRange, setIsRange] = useState(false);

  const formik = useFormik<IReviewsState['filter']>({
    initialValues: filterValues,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      dispatch(reviewsActions.setFilterValue({ filterState: values }));
    },
  });

  const [filterCanceled, setFilterCanceled] = useState(false);
  const handleCancelClick = () => {
    setFilterCanceled(true);
    dispatch(reviewsActions.resetFilter());
  };

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const handleCheckboxChange = (id, field: string) => (e) => {
    const { checked } = e.currentTarget;

    if (checked) {
      formik.setFieldValue(field, [...formik.values[field], id]);
    } else {
      const newValues = formik.values[field].filter(
        (productId) => productId !== id
      );
      formik.setFieldValue(field, newValues);
    }
  };

  const handleDoubleRangeChange = (a, b) => {
    formik.setFieldValue(
      'productVersions',
      initialValues.productVersions.slice(a, b + 1)
    );
  };

  const handleDateChange = (range: string) => {
    formik.setFieldValue('selectedRange', range);
  };

  return (
    <div className="review-filter-form border-left border-gray pt-4 px-4">
      <div className="mb-3">
        <h4>{t('reviews.filterForm.header')}</h4>
      </div>
      <Form
        onSubmit={formik.handleSubmit}
        className="d-flex flex-column flex-grow-1 overflow-hidden"
      >
        <div
          className="d-flex flex-column flex-grow-1"
          style={{ overflow: 'hidden auto' }}
        >
          <h5 className="mb-2">{t('reviews.filterForm.product.header')}</h5>
          <FormGroup check>
            {initialValues.productNames.map((productId) => (
              <Row key={productId}>
                <CustomInput
                  type="checkbox"
                  name="productName"
                  id="checkbox.productName"
                  label={
                    <span className="font-ag2 gray-color-2">
                      {productNameMapping[productId]}
                    </span>
                  }
                  onChange={handleCheckboxChange(productId, 'productNames')}
                  checked={formik.values.productNames.includes(productId)}
                />
              </Row>
            ))}
          </FormGroup>

          <h5 className="mt-4 mb-2">
            {t('reviews.filterForm.productVersion.header')}
          </h5>

          <CustomInput
            className="ml-1 mb-2"
            id="radio.version"
            label={
              <span className="font-ag2 gray-color-2">
                {t('reviews.filterForm.productVersion.specificVersions')}
              </span>
            }
            type="radio"
            name="product.version"
            checked={!isRange}
            onChange={() => setIsRange(false)}
          />

          <FormGroup check>
            {initialValues.productVersions.map((productVersionId) => (
              <Row key={productVersionId}>
                <CustomInput
                  className="ml-4"
                  type="checkbox"
                  label={
                    <span className="gray-color-2 font-ag2">
                      {productVersionId}
                    </span>
                  }
                  id={`checkbox.version.${productVersionId}`}
                  name="productVersion"
                  onChange={handleCheckboxChange(
                    productVersionId,
                    'productVersions'
                  )}
                  checked={formik.values.productVersions.includes(
                    productVersionId
                  )}
                  disabled={isRange}
                />
              </Row>
            ))}
          </FormGroup>

          <CustomInput
            className="mt-3"
            id="radio.version.range"
            label={
              <span className="font-ag2 gray-color-2">
                {t('reviews.filterForm.productVersion.rangeVersions')}
              </span>
            }
            type="radio"
            name="product.version"
            checked={isRange}
            onChange={() => setIsRange(true)}
          />

          <div className="mt-3 mb-4 pr-2">
            <VersionsRange
              productVersions={initialValues.productVersions}
              handleDoubleRangeChange={handleDoubleRangeChange}
              isRange={isRange}
              filterCanceled={filterCanceled}
              setFilterCanceled={setFilterCanceled}
            ></VersionsRange>
          </div>

          <div className="mt-3">
            <h5 className="mb-3">{t('reviews.filterForm.dateRange.header')}</h5>

            <div className="position-relative overflow-visible">
              <DatePickerRange
                onChange={handleDateChange}
                value={formik.values.selectedRange}
                withYTranslate={true}
              />
            </div>
          </div>

          <div className="flex-grow-1" />
        </div>

        <div className="position-sticky pb-3 bg-body" style={{ bottom: 0 }}>
          <div>
            <Button
              block
              className="submit-button"
              type="submit"
              color="primary"
            >
              {t('reviews.filterForm.buttons.submit')}
            </Button>
          </div>
          <div>
            <Button block onClick={handleCancelClick} color="secondary">
              {t('reviews.filterForm.buttons.cancel')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
