import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomInput } from 'reactstrap';
import { LicenseFilter as LicenseFilterValues } from '../../../utils/constants';

interface ILicenseFilterSelectProps {
  value: LicenseFilterValues;
  onChange: (value) => void;
  translationPrefix: string;
}

export const LicenseFilterSelect: React.FunctionComponent<ILicenseFilterSelectProps> = ({
  value,
  onChange,
  translationPrefix,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center pt-3 flex-wrap justify-content-between">
      <div className="mr-2 text-overflow-ellipsis">
        <div className="col-form-label text-nowrap font-ag2 gray-color-2 text-overflow-ellipsis">
          {/* {t(`${translationPrefix}.licenseFilter.label`)} */}
        </div>
      </div>
      <div className="d-flex flex-row flex-wrap">
        <CustomInput
          onChange={() => {
            onChange(LicenseFilterValues.All);
          }}
          checked={value === LicenseFilterValues.All}
          label={
            <div
              className="font-ag2"
              style={{ lineHeight: '20px', marginLeft: -2 }}
            >
              {t(`${translationPrefix}.licenseFilter.all`)}
            </div>
          }
          type="radio"
          id="radio.all"
          className="mr-2"
        />

        <CustomInput
          onChange={() => {
            onChange(LicenseFilterValues.WithLicense);
          }}
          id="radio.withLicense"
          checked={value === LicenseFilterValues.WithLicense}
          label={
            <div
              className="font-ag2"
              style={{ lineHeight: '20px', marginLeft: -2 }}
            >
              {t(`${translationPrefix}.licenseFilter.withLicense`)}
            </div>
          }
          type="radio"
          className="mr-2"
        />

        <CustomInput
          onChange={() => {
            onChange(LicenseFilterValues.NoLicense);
          }}
          id="radio.noLicense"
          checked={value === LicenseFilterValues.NoLicense}
          label={
            <div
              className="font-ag2"
              style={{ lineHeight: '20px', marginLeft: -2 }}
            >
              {t(`${translationPrefix}.licenseFilter.noLicense`)}
            </div>
          }
          type="radio"
          className="mr-2"
        />
      </div>
    </div>
  );
};
