import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PageHeader } from 'web-core/lib/web-components/PageHeader/PageHeader';
import {
  fetchFilteredIps,
  fetchFilteredIpTypes,
} from '../../store/filteredIpsSlice';
import { FilteredIpsTable } from './FilteredIpsTable';
import { FilteredIpsTableButtonGroup } from './FilteredIpsTableButtonGroup';

export const FilteredIps = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFilteredIps());
    dispatch(fetchFilteredIpTypes());
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column h-100 w-100 px-3 pt-3 overflow-hidden">
      <div>
        <PageHeader
          buttons={
            <div>
              <FilteredIpsTableButtonGroup />
            </div>
          }
          title={t('filteredIps.header')}
        />
      </div>
      <div className="flex-grow-1 overflow-auto my-4">
        <FilteredIpsTable />
      </div>
    </div>
  );
};
