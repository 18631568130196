import cn from 'classnames';
import { fill } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeChangeTracker } from 'utils/utils';
import { InobitecIcon } from 'web-icons';
import { star } from 'web-icons/svgIcons';
import { localizedFormat } from '../../lib/dateFns';
import { ReviewDto } from '../../types/dto';
import { themes } from '../../utils/constants';
import { featureIcons } from '../../utils/iconMappings';
import { ViewerProLogo } from './ViewerProLogo';

interface IReviewProps {
  review: ReviewDto;
}

export const Review: React.FunctionComponent<IReviewProps> = ({
  review,
}: IReviewProps) => {
  const { t, i18n } = useTranslation();
  const theme = useThemeChangeTracker();

  const {
    feature: { id: featureId, name: featureName },
    product: { name: productName },
    productVersion,
    reason,
    score,
    time,
    email,
  } = review;

  const stars = fill(Array(5).fill(false), true, 0, score).map(
    (hasStar, index) => {
      const iconClasses = cn({
        'empty-star': !hasStar && theme === themes.themeLight,
        'empty-star__dark': !hasStar && theme === themes.themeDark,
      });
      return <InobitecIcon icon={star} key={index} className={iconClasses} />;
    }
  );

  return (
    <div className="review bg-gray-3">
      <div className="review__header">
        <div>
          <div className="logo">
            <ViewerProLogo />

            <div className="d-inline-flex flex-column ml-3">
              <div className="logo__company-name">
                {t('reviews.reviewList.review.companyName')}
              </div>
              <div className="logo__product-name">{productName}</div>
            </div>
          </div>
          <div>
            <span className="text-gray-3">Версия:</span> {productVersion}
          </div>
        </div>
        <div>
          {featureName}
          {featureIcons[featureId] && (
            <InobitecIcon
              className="ml-3 text-gray-5"
              icon={featureIcons[featureId]}
              size="1x"
            />
          )}
        </div>
      </div>

      <div className="review__reason">{reason}</div>

      <div className="review__footer">
        <div>
          {stars}
          <span className="text-gray-3 ml-1">{score}/5</span>
        </div>
        <div className="d-flex flex-column">
          <div className="text-gray-3">
            {localizedFormat(new Date(time), i18n.language, 'Pp')}
          </div>
          {email && (
            <div className="text-gray-3 font-ag2 text-right">{email}</div>
          )}
        </div>
      </div>
    </div>
  );
};
