import { nanoid } from '@reduxjs/toolkit';
import { groupBy, keys, some } from 'lodash';
import { IGroupedDownloads } from '../../types/data';
import { DownloadDto } from '../../types/dto';

export const filterByProduct = (downloads: DownloadDto[], productName) => {
  if (productName === 'all') return downloads;
  return downloads.map((i) => ({
    ...i,
    productDownloadInfo: i.productDownloadInfo.filter(({ product }) => {
      return product.name === productName;
    }),
  }));
};

export const parseDownloads = (downloads: DownloadDto[]): IGroupedDownloads => {
  const result = {
    product: [],
    os: [],
    edition: [],
    arch: [],
    dateByInfoId: {},
  };

  downloads.forEach(({ productDownloadInfo, date, id }) => {
    result.dateByInfoId[id] = date;
    productDownloadInfo.forEach((info) => {
      const { product, arch, edition, os } = info;
      if (!some(result.product, { name: product.name })) {
        result.product.push(product);
      }
      if (!some(result.arch, { name: arch })) {
        const newData = {
          id: nanoid(),
          name: arch,
        };
        result.arch.push(newData);
      }
      if (!some(result.edition, { name: edition })) {
        const newData = {
          id: nanoid(),
          name: edition,
        };
        result.edition.push(newData);
      }
      if (!some(result.os, { name: os })) {
        const newData = {
          id: nanoid(),
          name: os,
        };
        result.os.push(newData);
      }
    });
  });

  return result;
};

export const extractInfo = (groupedInfo) => {
  const result = {};
  groupedInfo.forEach((info) => {
    const infoKeys = keys(info);
    infoKeys.forEach((i) => {
      if (!!result[i]) {
        result[i] = [...result[i], ...info[i]];
      } else {
        result[i] = info[i];
      }
    });
  });
  return result;
};

export const getGroupByPath = (downloads: DownloadDto[], path) => {
  const groupedByInfo = downloads.map(({ productDownloadInfo }) => {
    return groupBy(productDownloadInfo, path);
  });
  return extractInfo(groupedByInfo);
};

export const groupByProduct = (downloads: DownloadDto[]) => {
  return getGroupByPath(downloads, 'product.name');
};

export const groupByEdition = (downloads: DownloadDto[]) => {
  return getGroupByPath(downloads, 'edition');
};

export const groupByArch = (downloads: DownloadDto[]) => {
  return getGroupByPath(downloads, 'arch');
};

export const groupByOs = (downloads: DownloadDto[]) => {
  return getGroupByPath(downloads, 'os');
};
