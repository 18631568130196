import { requestWithAuth } from './config';

const resource = '/statistics/downloads';

export const getAll = () => {
  return requestWithAuth.get(resource);
};

export const getAppDownloads = (data: {
  params: {
    from: string;
    to: string;
    productIds: string[];
  };
  accept?: 'application/json' | 'text/csv';
}) => {
  return requestWithAuth.get('/statistics/downloads', {
    params: data.params,
    headers: {
      Accept: data.accept ?? 'application/json',
    },
  });
};
