import { useFormik } from 'formik';
import { noop, toNumber } from 'lodash';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../app/rootReducer';
import { createProductRelease } from '../../store/productReleasesSlice';
import { productsSelectors } from '../../store/productsSlice';
import { getProductReleaseValidationSchema } from '../../utils/validationSchemas';
import { DatePicker } from '../common/DatePicker/DatePicker';

type CreateFormProps = {
  onCancel?: () => void;
};
export const CreateForm: FunctionComponent<CreateFormProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const products = useTypedSelector(productsSelectors.selectAll);

  const { onCancel } = props;

  const formik = useFormik({
    initialValues: {
      version: '',
      productId: products[0]?.id,
      date: undefined,
    },
    validationSchema: getProductReleaseValidationSchema(t),
    onSubmit: async (values) => {
      const { version, productId, date } = values;
      await dispatch(
        createProductRelease({
          productId: toNumber(productId),
          version: version.trim(),
          date: date,
        })
      );
      await dispatch(onCancel);
    },
    validateOnChange: false,
  });

  const versionInputRef = useRef(null);
  useEffect(() => {
    versionInputRef.current.focus();
  }, []);

  const handleDateChange = (date) => {
    formik.setFieldValue('date', date);
  };

  const isFetching = formik.isSubmitting && !formik.isValidating;
  const isSubmitButtonDisabled = isFetching || !formik.dirty;

  return (
    <form onSubmit={formik.handleSubmit} className="font-ag2 gray-color-2">
      <div className="form-group row">
        <label
          htmlFor="productId"
          className="col-sm-4 col-form-label word-wrap"
        >
          {t('createProductRelease.productId')}
        </label>
        <div className="col-sm-8 d-flex align-items-center flex-column">
          <select
            id="productId"
            name="productId"
            className="form-control"
            value={formik.values.productId}
            onChange={formik.handleChange}
          >
            {products.map((p) => {
              return <option value={p.id} label={p.name} key={p.id} />;
            })}
          </select>
          <small className="d-block w-100 text-danger text-right">
            {formik.errors.productId}
          </small>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="version" className="col-sm-4 col-form-label word-wrap">
          {t('createProductRelease.version')}
        </label>
        <div className="col-sm-8 d-flex align-items-center flex-column">
          <input
            type="text"
            className="form-control"
            id="version"
            name="version"
            value={formik.values.version}
            onChange={formik.handleChange}
            ref={versionInputRef}
          />
          <small className="d-block w-100 text-danger text-right">
            {formik.errors.version}
          </small>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="date" className="col-sm-4 col-form-label word-wrap">
          {t('createProductRelease.date')}
        </label>
        <div className="col-sm-8 d-flex align-items-start flex-column">
          <DatePicker value={formik.values.date} onChange={handleDateChange} />
          <small className="d-block w-100 text-danger text-right">
            {formik.errors.date}
          </small>
        </div>
      </div>
      <div className="form-group row justify-content-end mb-2">
        <div className="mr-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-1"
            disabled={isSubmitButtonDisabled}
          >
            {t('createProductRelease.submitCreate')}
          </button>
        </div>
      </div>
    </form>
  );
};

CreateForm.defaultProps = {
  onCancel: noop,
};
