import {
  cdr,
  clut,
  colonoscopy,
  dcmImage,
  ecg,
  editor,
  layers,
  mpr,
  print,
  volume,
} from 'web-icons/svgIcons';

export const featureIcons = {
  1: dcmImage,
  2: clut,
  3: editor,
  4: volume,
  5: mpr,
  6: colonoscopy,
  7: layers,
  8: ecg,
  9: cdr,
  10: print,
};
