import { useFormik } from 'formik';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../app/rootReducer';
import {
  filteredIpsSelectors,
  updateFilteredIp,
} from '../../store/filteredIpsSlice';
import { FilteredIpsDto } from '../../types/dto';
import { getFilteredIpValidationSchema } from '../../utils/validationSchemas';

type UpdateFormProps = {
  filteredIp: FilteredIpsDto;
  onCancel?: () => void;
};

export const UpdateForm: FunctionComponent<UpdateFormProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { filteredIp, onCancel } = props;
  const types = useTypedSelector(filteredIpsSelectors.filteredIpTypes);

  const formik = useFormik({
    initialValues: {
      ip: filteredIp.ip,
      type: filteredIp.type,
    },
    validationSchema: getFilteredIpValidationSchema(t),
    onSubmit: async (values) => {
      const { ip, type } = values;
      await dispatch(
        updateFilteredIp({
          id: filteredIp.id,
          ip,
          type,
        })
      );

      await dispatch(onCancel);
    },
    validateOnMount: true,
    validateOnChange: true,
  });

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const isFetching = formik.isSubmitting && !formik.isValidating;
  const isSubmitButtonDisabled = isFetching || !formik.dirty;

  return (
    <form onSubmit={formik.handleSubmit} className="font-ag2 gray-color-2">
      <div className="form-group row">
        <label
          htmlFor="ip"
          className="col-sm-4 col-form-label text-overflow-ellipsis"
        >
          {t('updateFilteredIp.ip')}
        </label>
        <div className="col-sm-8 d-flex align-items-center flex-column">
          <input
            ref={inputRef}
            type="text"
            className="form-control"
            id="ip"
            name="ip"
            value={formik.values.ip}
            onChange={formik.handleChange}
          />
          <small className="d-block w-100 text-danger text-right">
            {formik.errors.ip}
          </small>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="type" className="col-sm-4 col-form-label word-wrap">
          {t('updateFilteredIp.type')}
        </label>
        <div className="col-sm-8 d-flex align-items-center flex-column">
          <select
            id="type"
            name="type"
            className="form-control"
            value={formik.values.type}
            onChange={formik.handleChange}
          >
            {Object.keys(types).map((item) => {
              return (
                <option value={types[item]} label={types[item]} key={item} />
              );
            })}
          </select>
          <small className="d-block w-100 text-danger text-right">
            {formik.errors.type}
          </small>
        </div>
      </div>
      <div className="form-group row justify-content-end mb-1">
        <div className="mr-3">
          <button
            type="button"
            className="btn btn-secondary mr-1"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitButtonDisabled}
          >
            {t('updateFilteredIp.submitUpdate')}
          </button>
        </div>
      </div>
    </form>
  );
};

UpdateForm.defaultProps = {
  onCancel: () => {},
};
