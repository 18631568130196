import { compareAsc } from 'date-fns';
import { ReviewDto } from '../../types/dto';

export const sortReviews = (field: string, order: string) => (
  a: ReviewDto,
  b: ReviewDto
) => {
  const reverse = order === 'asc' ? 1 : -1;
  let result: number;

  switch (field) {
    case 'date':
      result = compareAsc(new Date(a.time), new Date(b.time));
      break;
    case 'productVersion':
      result = a.productVersion.toString().localeCompare(b.productVersion);
      break;
    case 'score':
      result = a.score - b.score;
      break;
    default:
      result = 1;
      break;
  }

  return result * reverse;
};
