import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBody, ModalHeader } from 'reactstrap';
import { IModalProps } from '../../../types';
import { FilterProductReleasesTableForm } from '../../ProductReleases/FilterProductReleasesTableForm';

export const FilterProductReleases: FunctionComponent<IModalProps> = (
  props
) => {
  const { hideModal } = props;
  const { t } = useTranslation();

  return (
    <>
      <ModalHeader className="font-montserrat m-0 border-0">
        {t('common.filter')}
      </ModalHeader>
      <ModalBody>
        <FilterProductReleasesTableForm onCancel={hideModal} />
      </ModalBody>
    </>
  );
};
