import { formatISO } from 'date-fns';
import { useFormik } from 'formik';
import { toNumber } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../app/rootReducer';
import { updateProductRelease } from '../../store/productReleasesSlice';
import { productsSelectors } from '../../store/productsSlice';
import { ProductReleaseDto } from '../../types/dto';
import { getProductReleaseValidationSchema } from '../../utils/validationSchemas';
import { DatePicker } from '../common/DatePicker/DatePicker';

type UpdateFormProps = {
  productRelease: ProductReleaseDto;
  onCancel?: () => void;
};
export const UpdateForm: FunctionComponent<UpdateFormProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { productRelease, onCancel } = props;
  const products = useTypedSelector(productsSelectors.selectAll);

  const formik = useFormik({
    initialValues: {
      version: productRelease.version,
      productId: productRelease.product?.id,
      date: formatISO(new Date(productRelease.date), {
        representation: 'date',
      }),
    },
    validationSchema: getProductReleaseValidationSchema(t),
    onSubmit: async (values) => {
      const { version, productId, date } = values;
      await dispatch(
        updateProductRelease({
          productId: toNumber(productId),
          version: version.trim(),
          date,
          productReleaseId: productRelease.id,
        })
      );

      await dispatch(onCancel);
    },
    validateOnMount: true,
    validateOnChange: false,
  });

  const handleDateChange = (date) => {
    formik.setFieldValue('date', date);
  };

  const isFetching = formik.isSubmitting && !formik.isValidating;
  const isSubmitButtonDisabled = isFetching || !formik.dirty;

  return (
    <form onSubmit={formik.handleSubmit} className="font-ag2 gray-color-2">
      <div className="form-group row">
        <label
          htmlFor="productId"
          className="col-sm-4 col-form-label word-wrap"
        >
          {t('updateProductRelease.productId')}
        </label>
        <div className="col-sm-8 d-flex align-items-center flex-column">
          <select
            id="productId"
            name="productId"
            className="form-control"
            value={formik.values.productId}
            onChange={formik.handleChange}
          >
            {products.map((p) => {
              return <option value={p.id} label={p.name} key={p.id} />;
            })}
          </select>
          <small className="d-block w-100 text-danger text-right">
            {formik.errors.productId}
          </small>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="version" className="col-sm-4 col-form-label word-wrap">
          {t('updateProductRelease.version')}
        </label>
        <div className="col-sm-8 d-flex align-items-center flex-column">
          <input
            type="text"
            className="form-control"
            id="version"
            name="version"
            value={formik.values.version}
            onChange={formik.handleChange}
          />
          <small className="d-block w-100 text-danger text-right mr-5">
            {formik.errors.version}
          </small>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="date" className="col-sm-4 col-form-label word-wrap">
          {t('updateProductRelease.date')}
        </label>
        <div className="col-sm-8 d-flex align-items-center flex-column">
          <DatePicker value={formik.values.date} onChange={handleDateChange} />
          <small className="d-block w-100 text-danger text-right">
            {formik.errors.date}
          </small>
        </div>
      </div>
      <div className="form-group row justify-content-end mb-2">
        <div className="mr-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-1"
            disabled={isSubmitButtonDisabled}
          >
            {t('updateProductRelease.submitUpdate')}
          </button>
        </div>
      </div>
    </form>
  );
};

UpdateForm.defaultProps = {
  onCancel: () => {},
};
