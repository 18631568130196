import { LicenseFilter } from './constants';

export const selectByLicenseFilter = (data, filter: LicenseFilter) => {
  switch (filter) {
    case LicenseFilter.WithLicense:
      return data.filter((statRecord) => statRecord.hasLicense);
    case LicenseFilter.NoLicense:
      return data.filter((statRecord) => !statRecord.hasLicense);
    case LicenseFilter.All:
      const result = [];
      data.forEach((statRecord) => {
        const targetResultItem = result.find((resultItem) => {
          if (statRecord.system)
            return resultItem.system.id === statRecord.system.id;
          else
            return (
              resultItem.sender.id === statRecord.sender.id &&
              resultItem.context?.id === statRecord.context?.id
            );
        });
        if (targetResultItem) {
          targetResultItem.instanceCount += statRecord.instanceCount;
        } else {
          result.push({ ...statRecord });
        }
      });
      return result;
    default:
      return data;
  }
};
