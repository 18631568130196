import { AxiosError } from 'axios';

const errorHandler = (error: any) => {
  console.error(error.toJSON && error.toJSON());
  // if (error.response) {
  //   // The request was made and the server responded with a status code
  //   // that falls out of the range of 2xx
  //   console.log(error.response.data);
  //   console.log(error.response.status);
  //   console.log(error.response.headers);
  // } else if (error.request) {
  //   // The request was made but no response was received
  //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //   // http.ClientRequest in node.js
  //   console.log(error.request);
  // } else {
  //   // Something happened in setting up the request that triggered an Error
  //   console.log('Error', error.message);
  // }
  console.error(error.config);
};

export const thunkErrorHandler = (err: AxiosError, thunkAPI) => {
  errorHandler(err);
  if (!err.response) throw err;
  return thunkAPI.rejectWithValue(err.response.data);
};

export const asyncThunkHandler = (
  asyncFunction,
  prepareCallback?: (data) => typeof data
) => async (data, thunkAPI) => {
  try {
    const response = await asyncFunction(data);
    return prepareCallback ? prepareCallback(response.data) : response.data;
  } catch (err) {
    return thunkErrorHandler(err, thunkAPI);
  }
};
