import { useTypedSelector } from 'app/rootReducer';
import cn from 'classnames';
import { format } from 'date-fns';
import { localizedFormat } from 'lib/dateFns';
import { flatten, groupBy, omitBy } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, CustomInput } from 'reactstrap';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  addAppDownloads,
  downloadsActions,
  downloadsSelectors,
} from 'store/downloads/downloadsSlice';
import { EChartView, ESelectedProduct, ESelectedType } from 'types/downloads';
import { BlockUI } from 'web-core/lib/web-components';
import { chartViewOptions, fillEmptyDatesWithZeros } from '../utils';
import { ChartSettings } from './ChartSettings';
import { DownloadsAnalytics } from './DownloadsAnalytics';

interface ICompareChartProps {
  data;
  period;
  isDownloadsLoading: boolean;
}

const useStrokes = () => {
  const [colors, setColors] = useState<{ [key: string]: string }>({});

  const colorsPull = [
    'rgba(49, 133, 89, 1)',
    'rgba(0, 108, 156, 1)',
    'rgba(150, 60, 189, 1)',
    'rgba(139, 0, 0, 1)',
    'rgba(255, 165, 0, 1)',
    'rgba(72, 61, 139, 1)',
    'rgba(51, 187, 51, 1)',
    'rgba(170, 170, 170, 1)',
    'rgba(51, 187, 187, 1)',
    'rgba(128, 0, 0, 1)',
    'rgba(0, 128, 128, 1)',
    'rgba(0, 0, 128, 1)',
    'rgba(220, 0, 0, 1)',
    'rgba(128, 128, 0, 1)',
  ];

  const lastSelectedColorFromPool = useRef(-1);

  return {
    fadeOthersExceptKey: (exceptionKey: string) => {
      const newColors = Object.entries(colors).map(([key, color]) => {
        const isExceptedKey = exceptionKey === key;
        const split = color.split(', ');
        split[3] = isExceptedKey ? '1)' : '0.15)';
        const newColor = split.join(', ');
        return [key, newColor];
      });
      setColors(Object.fromEntries(newColors));
    },
    fadeOthersExcept: (exceptionColor: string) => {
      const firstColorSplit = exceptionColor.split(', ');
      const newColors = Object.entries(colors).map(([key, color]) => {
        const colorSplit = color.split(', ');

        const isExceptedColor = [0, 1, 2]
          .map((i) => firstColorSplit[i] === colorSplit[i])
          .every((e) => e === true);

        const split = color.split(', ');
        split[3] = isExceptedColor ? '1)' : '0.15)';
        const newColor = split.join(', ');
        return [key, newColor];
      });
      setColors(Object.fromEntries(newColors));
    },
    highlightAll: () => {
      const newColors = Object.entries(colors).map(([key, color]) => {
        const split = color.split(', ');
        split[3] = '1';
        const newColor = split.join(', ');
        return [key, newColor];
      });
      setColors(Object.fromEntries(newColors));
    },
    get: (key: string) => {
      const selectedColor = colors[key];
      if (!selectedColor) {
        lastSelectedColorFromPool.current += 1;
        const colorFromPull = colorsPull[lastSelectedColorFromPool.current];
        let color = null;

        if (!colorFromPull) {
          const randomBetween = (min, max) =>
            min + Math.floor(Math.random() * (max - min + 1));
          const r = randomBetween(0, 255);
          const g = randomBetween(0, 255);
          const b = randomBetween(0, 255);
          const rgb = `rgba(${r}, ${g}, ${b}, 1)`;
          color = rgb;
        } else color = colorFromPull;

        setColors((prev) => {
          return { ...prev, ...{ [key]: color } };
        });

        return color;
      } else {
        return selectedColor;
      }
    },
  };
};

const ChartLegend = (
  e,
  dataKeysForDiagram: string[],
  hiddenKeys: string[],
  setHiddenKeys,
  strokes: ReturnType<typeof useStrokes>
) => {
  const colors = {};

  e.payload.forEach((entry) => (colors[entry.dataKey] = entry.color));

  return (
    <div className="d-flex w-100 align-items-center justify-content-center">
      {[...dataKeysForDiagram, ...hiddenKeys].sort().map((key) => {
        return (
          <div
            key={key}
            onClick={() => {
              if (!hiddenKeys.includes(key) && dataKeysForDiagram.length > 1) {
                setHiddenKeys([...hiddenKeys, key]);
                strokes.highlightAll();
              } else {
                setHiddenKeys(hiddenKeys.filter((_key) => _key !== key));
                strokes.fadeOthersExceptKey(key);
              }
            }}
            className="d-flex align-items-center cursor-pointer"
            onMouseLeave={() => {
              strokes.highlightAll();
            }}
            onMouseEnter={() => {
              if (!hiddenKeys.includes(key))
                strokes.fadeOthersExcept(colors[key]);
            }}
          >
            <div
              style={{
                color: colors[key],
                fontSize: 35,
                marginBottom: 5,
                marginRight: 4,
              }}
            >
              &bull;
            </div>
            <div
              style={{
                padding: '10px 5px',
                textDecoration: hiddenKeys.includes(key) ? 'line-through' : '',
              }}
              className={cn('cursor-pointer', {
                'color-gray': hiddenKeys.includes(key),
              })}
            >
              {key}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const CompareChart: React.FC<ICompareChartProps> = (props) => {
  const { period, isDownloadsLoading } = props;

  const { t, i18n } = useTranslation();

  const splitter = useMemo(() => {
    return i18n.language === 'ru' ? '.' : '/';
  }, [i18n.language]);

  const dispatch = useDispatch();

  const _selectedRange = useTypedSelector(
    downloadsSelectors.selectState
  ).selectedRange.split('&&');

  const selectedRange = (() => {
    const firstDate = new Date(_selectedRange[0]);
    const secondDate = new Date(_selectedRange[1]);

    if (
      firstDate.getMonth() > secondDate.getMonth() &&
      firstDate.getDate() > secondDate.getDate()
    ) {
      return [_selectedRange[1], _selectedRange[1]];
    }

    return _selectedRange;
  })();

  const {
    selectedType,
    selectedProduct,
    selectedChartView,
    // minDate,
    // maxDate,
  } = useTypedSelector(downloadsSelectors.selectState);

  const selectedRangeFrom = useMemo(() => {
    return new Date(selectedRange[0]);
  }, [selectedRange]);

  const selectedRangeTo = useMemo(() => {
    return new Date(selectedRange[1]);
  }, [selectedRange]);

  const [selectedPeriod, setSelectedPeriod] = useState({
    from: {
      month: selectedRangeFrom.getMonth(),
      day: selectedRangeFrom.getDate(),
    },
    to: {
      month: selectedRangeTo.getMonth(),
      day: selectedRangeTo.getDate(),
    },
  });

  const [appliedPeriod, setAppliedPeriod] = useState(selectedPeriod);

  const months = t('dayPicker.months', { returnObjects: true }) as string[];
  const years = [];
  for (let i = 2020; i <= new Date().getFullYear(); i += 1) {
    years.push(i);
  }

  const [_selectedYears, setSelectedYears] = useState(
    Object.fromEntries([
      ...years.map((year) =>
        year >= selectedRangeFrom.getFullYear() &&
        year <= selectedRangeTo.getFullYear()
          ? [year, true]
          : [year, false]
      ),
    ])
  );

  const selectedYears = useMemo(() => {
    const years = Object.keys(_selectedYears).filter(
      (year) => _selectedYears[year] === true
    );
    return years;
  }, [_selectedYears]);

  const handleApply = useCallback(() => {
    setAppliedPeriod(selectedPeriod);

    selectedYears.forEach((year) => {
      const from = format(
        new Date(
          Number(year),
          selectedPeriod.from.month,
          selectedPeriod.from.day
        ),
        'yyyy-MM-dd'
      );

      const to = format(
        new Date(Number(year), selectedPeriod.to.month, selectedPeriod.to.day),
        'yyyy-MM-dd'
      );

      dispatch(
        addAppDownloads({
          params: {
            from,
            to,
            productIds: ['1', '2'],
          },
        })
      );
    });
  }, [dispatch, selectedPeriod, selectedYears]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    handleApply();
  }, [selectedYears]); // eslint-disable-line

  const parsedDownloads = useTypedSelector(
    downloadsSelectors.selectParsedDownloads
  );

  const dataKeys: string[] = parsedDownloads[selectedType]
    .map((v: any) => v.name)
    .filter((v) => {
      if (!v) return false;
      if (selectedType === ESelectedType.product) {
        if (selectedProduct === ESelectedProduct.all) return true;
        return v === selectedProduct;
      }
      return true;
    });

  const [hiddenKeys, setHiddenKeys] = useState([]);

  const dataKeysForDiagram = useMemo(() => {
    return flatten(
      dataKeys.map((key) => {
        const keys = selectedYears
          .map((year) => `${key} (${year})`)
          .filter((key) => !hiddenKeys.includes(key));
        return keys;
      })
    );
  }, [dataKeys, selectedYears, hiddenKeys]);

  // Данные для диаграммы в нужном формате получаются путём преобразования props.data в несколько этапов
  const dataForDiagram = useMemo(() => {
    let dataWithExtraDates = fillEmptyDatesWithZeros(props.data, dataKeys, {
      day: selectedPeriod.from.day,
      month: selectedPeriod.from.month,
    });

    const dataForDiagram = dataWithExtraDates.map((dataRecord) => {
      // if (period === 'month') return dataRecord;
      const newDataRecord = { date: dataRecord.date };

      const date = new Date(dataRecord.date);
      const year = date.getFullYear();

      Object.keys(dataRecord)
        .filter((field) => field !== 'date')
        .forEach((field) => {
          newDataRecord[`${field} (${year})`] = dataRecord[field];
        });

      const findFormat = () => {
        if (i18n.language === 'ru') {
          return 'dd.MM';
        } else {
          return 'MM/dd';
        }
      };

      return {
        ...newDataRecord,
        date: localizedFormat(date, i18n.language, findFormat() as any),
      };
    });

    const dataObject = {};
    dataForDiagram.forEach((entry) => {
      const keys = Object.keys(entry).filter((entry) => entry !== 'date');
      const object = {};
      keys.forEach((key) => (object[key] = entry[key]));
      dataObject[entry.date] = dataObject[entry.date]
        ? { ...dataObject[entry.date], ...object }
        : object;
    });

    const orderedDataObject = Object.keys(dataObject)
      .sort((a, b) => {
        const aSplit = a.split(splitter);
        const bSplit = b.split(splitter);

        const aMonth = i18n.language === 'ru' ? aSplit[1] : aSplit[0];
        const aDay = i18n.language === 'ru' ? aSplit[0] : aSplit[1];

        const bMonth = i18n.language === 'ru' ? bSplit[1] : bSplit[0];
        const bDay = i18n.language === 'ru' ? bSplit[0] : bSplit[1];

        const aCost = Number(aDay) + Number(aMonth) * 31;
        const bCost = Number(bDay) + Number(bMonth) * 31;
        if (aCost > bCost) return 1;
        else if (aCost === bCost) return 0;
        else return -1;
      })
      .reduce((obj, key) => {
        obj[key] = dataObject[key];
        return obj;
      }, {});

    const _dataForDiagram = Object.keys(orderedDataObject)
      .map((dateKey) => {
        const entry = { date: dateKey, ...orderedDataObject[dateKey] };
        return entry;
      })
      .filter((entry) => {
        const { date } = entry;

        const dateSplit = date.split(splitter);
        const day = Number(
          i18n.language === 'ru' ? dateSplit[0] : dateSplit[1]
        );
        const month = Number(
          i18n.language === 'ru' ? dateSplit[1] : dateSplit[0]
        );

        const _date = new Date(0, month - 1, day);
        const left = new Date(
          0,
          appliedPeriod.from.month,
          appliedPeriod.from.day
        );
        const right = new Date(0, appliedPeriod.to.month, appliedPeriod.to.day);

        if (left <= _date && _date <= right) return true;
        return false;
      });
    return _dataForDiagram;
  }, [
    appliedPeriod,
    i18n.language,
    props.data,
    splitter,
    dataKeys,
    selectedPeriod.from.day,
    selectedPeriod.from.month,
  ]);

  const isDisabled = useMemo(() => {
    return (
      selectedPeriod.to.day + selectedPeriod.to.month * 31 <
        selectedPeriod.from.day + selectedPeriod.from.month * 31 ||
      Number.isNaN(Number(selectedPeriod.from.day)) ||
      Number.isNaN(Number(selectedPeriod.to.day))
    );
  }, [selectedPeriod]);

  const rangeInput = useCallback(
    (fromOrTo: 'from' | 'to') => {
      return (
        <div className="d-flex">
          <div>
            <select
              style={{ minWidth: 90 }}
              onChange={(e) => {
                setSelectedPeriod({
                  ...selectedPeriod,
                  [fromOrTo]: {
                    ...selectedPeriod[fromOrTo],
                    month: Number(e.target.value),
                  },
                });
              }}
              value={selectedPeriod[fromOrTo].month}
            >
              {months.map((month, i) => (
                <option
                  disabled={
                    fromOrTo === 'from'
                      ? i > selectedPeriod.to.month
                      : i < selectedPeriod.from.month
                  }
                  value={i}
                  key={month}
                >
                  {month}
                </option>
              ))}
            </select>
          </div>
          <input
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                if (!isDisabled) handleApply();
              }
            }}
            value={selectedPeriod[fromOrTo].day}
            onChange={(e) => {
              setSelectedPeriod({
                ...selectedPeriod,
                [fromOrTo]: {
                  ...selectedPeriod[fromOrTo],
                  day: Number(e.target.value),
                },
              });
            }}
            placeholder={t('period.day')}
            min={1}
            max={31}
            style={{ maxHeight: 21, width: 80 }}
            type="number"
            className="form-control form-control-sm ml-1"
          ></input>
        </div>
      );
    },
    [handleApply, months, selectedPeriod, t, isDisabled]
  );

  const dataForDiagramMonths = useMemo(() => {
    const groupedData = groupBy(dataForDiagram, (obj) => {
      return i18n.language === 'ru'
        ? obj.date.split('.')[1]
        : obj.date.split('/')[0];
    });

    const reducedData = Object.keys(groupedData).map((monthNumber) => {
      const arrayToReduce = groupedData[monthNumber];

      const dataObject = {};
      arrayToReduce.forEach((entry) => {
        const keys = Object.keys(entry).filter((entry) => entry !== 'date');
        keys.forEach((key) => {
          dataObject[key] = (dataObject[key] ?? 0) + entry[key];
        });
      });
      dataObject['date'] = monthNumber;

      const ommitedReduced = omitBy(dataObject, Number.isNaN);
      return ommitedReduced;
    });

    const sortedData = Object.entries(reducedData)
      .sort(([, a], [, b]) => {
        return Number(a.date) - Number(b.date);
      })
      .map((entry) => entry[1]);

    return sortedData;
  }, [dataForDiagram, dataKeysForDiagram, i18n.language]);

  const totalDownloads = useMemo(() => {
    const reduced = (() => {
      const finalObject = {};
      dataForDiagram.forEach((entry) => {
        Object.entries(entry).forEach(([field, value]) => {
          if (field !== 'date') {
            finalObject[field] = finalObject[field] ?? 0;
            finalObject[field] += value;
          }
        });
      });
      return finalObject;
    })();

    hiddenKeys.forEach((key) => {
      reduced[key] = 0;
    });
    const ommitedReduced = omitBy(omitBy(reduced, Number.isNaN), (_, i) => {
      return i === 'date';
    });
    return ommitedReduced;
  }, [dataForDiagram, hiddenKeys]);

  const strokes = useStrokes();

  return (
    <>
      <div className="d-flex flex-wrap my-2 mt-4">
        <div className="mr-2"> {t('downloads.period')}:</div>
        <div className="mr-2"> {t('downloads.from')}</div>
        {rangeInput('from')}
        <div className="mx-2"> {t('downloads.to')}</div>
        {rangeInput('to')}

        <div className="ml-3">
          <Button
            disabled={isDisabled}
            onClick={() => {
              handleApply();
            }}
            color="primary"
            style={{ maxHeight: 21 }}
            size="sm"
          >
            {t('common.apply')}
          </Button>
        </div>

        <div className="ml-3">
          <CustomInput
            disabled={isDisabled}
            className="mr-2"
            id={`showAsMonths_checkbox`}
            type="checkbox"
            checked={period === 'month'}
            onChange={() => {
              dispatch(
                downloadsActions.selectPeriod({
                  period: period === 'day' ? 'month' : 'day',
                })
              );
            }}
            label={
              <div style={{ lineHeight: 1.75 }}>{t('downloads.monthly')}</div>
            }
          ></CustomInput>
        </div>
        <div className="ml-2">
          <select
            style={{ minWidth: 90 }}
            onChange={(e) => {
              dispatch(
                downloadsActions.selectChartView({
                  view: e.target.value as EChartView,
                })
              );
            }}
            value={selectedChartView}
          >
            {chartViewOptions.map((view) => (
              <option value={view} key={view}>
                {t(`downloads.chartViews.${view}`)}
              </option>
            ))}
          </select>
        </div>
        <div className="d-flex flex-grow-1 justify-content-end mr-7">
          <ChartSettings></ChartSettings>
        </div>
      </div>

      <div className="d-flex mt-4">
        <div className="mr-3"> {t('downloads.years')}: </div>
        {Object.keys(_selectedYears).map((year) => {
          return (
            <CustomInput
              key={year}
              disabled={
                isDisabled ||
                (selectedYears.length === 1 && _selectedYears[year])
              }
              className="mr-2"
              id={`checkboxYear${year}`}
              type="checkbox"
              checked={_selectedYears[year]}
              onChange={(e) => {
                setSelectedYears({
                  ..._selectedYears,
                  [year]: !_selectedYears[year],
                });
              }}
              label={<div style={{ lineHeight: 1.75 }}>{year}</div>}
            ></CustomInput>
          );
        })}
      </div>

      <DownloadsAnalytics
        dataForDiagramMonths={dataForDiagramMonths}
        dataForDiagram={dataForDiagram}
        totalDownloads={totalDownloads}
        diagramDataKeys={dataKeysForDiagram}
        dataKeys={dataKeys}
        selectedYears={selectedYears}
      ></DownloadsAnalytics>

      <BlockUI isBlocked={isDownloadsLoading} className="mt-3">
        <ResponsiveContainer width={'98%'} height="75%">
          <LineChart
            data={period === 'month' ? dataForDiagramMonths : dataForDiagram}
            margin={{
              top: 20,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid />
            <XAxis dataKey="date" allowDuplicatedCategory={true} />
            <YAxis type="number" />
            <Tooltip />
            <Legend
              content={(e) => {
                return ChartLegend(
                  e,
                  dataKeysForDiagram,
                  hiddenKeys,
                  setHiddenKeys,
                  strokes
                );
              }}
            />
            {dataKeysForDiagram.map((key, i) => {
              return (
                <Line
                  key={key}
                  dataKey={key}
                  stroke={strokes.get(key)}
                  strokeWidth={3}
                  dot={false}
                  type={selectedChartView}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </BlockUI>
    </>
  );
};
