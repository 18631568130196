import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BlockUI, TableComponent } from 'web-core/lib/web-components';
import { IColumnForTableComponent } from 'web-core/lib/web-components/TableComponent/TableComponent';
import { useTypedSelector } from '../../app/rootReducer';
import { localizedFormat } from '../../lib/dateFns';
import { fetchingStatesSelectors } from '../../store/fetchingStatesSlice';
import {
  productReleasesActions,
  productReleasesSelectors,
} from '../../store/productReleasesSlice';
import { filtersSelectors } from '../../store/tables/filtersSlice';
import { fetchingStatuses } from '../../utils/constants';

interface IRelease {
  id: number;
  productName: string;
  version: string;
  date: string;
}

export const ProductReleasesTable = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const productReleases = useTypedSelector(
    filtersSelectors.filteredProductReleases
  );
  const productReleasesFetchingState = useTypedSelector(
    fetchingStatesSelectors.productReleases
  );
  const selectedProductReleaseId = useTypedSelector(
    productReleasesSelectors.selectedId
  );

  useEffect(() => {
    if (
      selectedProductReleaseId &&
      !productReleases.find(
        (productRelease) => productRelease.id === selectedProductReleaseId
      )
    )
      dispatch(productReleasesActions.resetProductRelease());
  }, [dispatch, productReleases, selectedProductReleaseId]);

  const data = productReleases.map((i) => ({
    ...i,
    productName: i?.product?.name,
  }));

  const handleSelectRow = (id) => {
    dispatch(productReleasesActions.selectProductRelease({ id }));
  };

  const isFetching = productReleasesFetchingState === fetchingStatuses.pending;

  const [columnsState, setColumnsState] = useState<
    IColumnForTableComponent<IRelease>[]
  >([
    {
      isSortable: true,
      dataField: 'productName',
    },
    {
      isSortable: true,
      dataField: 'version',
    },
    {
      isSortable: true,
      orderType: 'date' as const,
      dataField: 'date',
      customFormatter: (value) => {
        return localizedFormat(new Date(value), i18n.language);
      },
    },
  ]);

  return (
    <div className="w-100 h-100 overflow-auto">
      <div>
        <BlockUI isBlocked={isFetching}>
          <TableComponent<IRelease>
            tableName="productReleases"
            selectedRow={selectedProductReleaseId}
            data={data}
            onSelectRow={handleSelectRow}
            columnsState={columnsState}
            setColumnsState={setColumnsState}
            keyField="id"
            defaultOrder={{
              orderDirection: 'desc',
              orderField: 'date',
              orderType: 'date',
            }}
          />
        </BlockUI>
      </div>
    </div>
  );
};
