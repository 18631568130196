import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ModalBody, ModalHeader } from 'reactstrap';
import { logout } from '../../store/authSlice';
import { commonActions } from '../../store/commonSlice';
import { errorsActions } from '../../store/errrosSlice';
import { browserRoutes } from '../../utils/constants';

type LogoutConfirmProps = {};
export const LogoutConfirm: FunctionComponent<LogoutConfirmProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hideModal = () => {
    dispatch(commonActions.hideModal());
  };

  const handleLogout = async () => {
    const resultAction = await dispatch(logout());
    //@ts-ignore
    if (logout.fulfilled.match(resultAction)) {
      dispatch(errorsActions.resetAll());
      hideModal();
      history.replace(browserRoutes.login);
    }
  };

  return (
    <>
      <ModalHeader className="font-montserrat m-0 border-0">
        {t('common.logout')}
      </ModalHeader>
      <ModalBody>
        <div className="gray-color-2 font-ag2">{t('common.logoutConfirm')}</div>
        <div className="form-group row justify-content-end mb-1">
          <div className="mr-3">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={hideModal}
            >
              {t('common.cancel')}
            </button>
            <button
              type="submit"
              className="btn btn-primary ml-1"
              onClick={handleLogout}
            >
              {t('common.yes')}
            </button>
          </div>
        </div>
      </ModalBody>
    </>
  );
};
