import { useFormik } from 'formik';
import { noop } from 'lodash';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../app/rootReducer';
import {
  filtersSelectors,
  resetValues,
  setValue,
} from '../../store/tables/filtersSlice';

type FilterOperationSystemsTableFormProps = {
  onCancel: () => void;
};

export const FilterOperationSystemsTableForm: FunctionComponent<FilterOperationSystemsTableFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { onCancel } = props;

  const filters = useTypedSelector(filtersSelectors.operationSystems);

  const formik = useFormik({
    initialValues: filters,
    onSubmit: (values) => {
      dispatch(setValue({ table: 'operationSystems', filters: values }));
      onCancel();
    },
  });

  const handleReset = () => {
    dispatch(resetValues({ table: 'operationSystems' }));
    formik.resetForm();
  };

  useEffect(() => {
    formik.resetForm({ values: filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const isSubmitButtonDisabled = !formik.dirty;

  return (
    <form onSubmit={formik.handleSubmit} className="font-ag2 gray-color-2">
      <div className="form-group row">
        <label htmlFor="name" className="col-sm-4 col-form-label text-break">
          {t('operationSystems.captions.systemName')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <input
            type="text"
            className="form-control"
            id="name"
            name="system.name"
            value={formik.values.system.name}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="version" className="col-sm-4 col-form-label text-break">
          {t('operationSystems.captions.systemVersion')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <input
            type="text"
            className="form-control"
            id="version"
            name="system.version"
            value={formik.values.system.version}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="arch" className="col-sm-4 col-form-label text-break">
          {t('operationSystems.captions.systemArch')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <input
            type="text"
            className="form-control"
            id="arch"
            name="system.arch"
            value={formik.values.system.arch}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="form-group row justify-content-end mt-4">
        <div className="mr-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            type="reset"
            className="btn btn-primary mx-1"
            onClick={handleReset}
          >
            {t('common.reset')}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitButtonDisabled}
          >
            {t('common.apply')}
          </button>
        </div>
      </div>
    </form>
  );
};

FilterOperationSystemsTableForm.defaultProps = {
  onCancel: noop,
};
