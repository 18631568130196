import { useFormik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removeFilteredIp } from '../../store/filteredIpsSlice';
import { FilteredIpsDto } from '../../types/dto';

type RemoveFormProps = {
  filteredIp: FilteredIpsDto;
  onCancel?: () => void;
};

export const RemoveForm: FunctionComponent<RemoveFormProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { filteredIp, onCancel } = props;

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      await dispatch(
        removeFilteredIp({
          id: filteredIp.id,
        })
      );
      await dispatch(onCancel);
    },
  });

  const isSubmitButtonDisabled = formik.isSubmitting;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="font-ag2 gray-color-2">
        {t('removeFilteredIp.question', { ip: filteredIp.ip })}
      </div>
      <div className="form-group row justify-content-end mb-2">
        <div className="mt-3">
          <button
            type="button"
            className="btn btn-secondary mr-1"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-danger mr-4"
            disabled={isSubmitButtonDisabled}
          >
            {t('removeFilteredIp.submitRemove')}
          </button>
        </div>
      </div>
    </form>
  );
};

RemoveForm.defaultProps = {
  onCancel: () => {},
};
