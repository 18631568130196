import { isObject, keys } from 'lodash';
import React, {
  ComponentType,
  LazyExoticComponent,
  useEffect,
  useState,
} from 'react';
import webThemes, { changeTheme as changeWebTheme } from 'web-core';
import { IDateRange, IThemes } from '../types';
import { dateRangeSeparator, localStorageKeys, themes } from './constants';

export const lazyWithRetry = (
  promise: Promise<{ default: ComponentType<any> }>,
  retriesLeft: number = 5,
  interval: number = 1000
): LazyExoticComponent<ComponentType<any>> => {
  return React.lazy(() => retry(() => promise, retriesLeft, interval));
};

const retry = (
  fn: () => Promise<{ default: ComponentType<any> }>,
  retriesLeft: number,
  interval: number
): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const getBrowserTheme = (): IThemes => {
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? themes.themeDark
    : themes.themeLight;
};

export const useThemeChangeTracker = () => {
  const [currentTheme, setCurrentTheme] = useState(getTheme());
  useEffect(() => {
    const checkTheme = () => {
      const theme = localStorage.getItem('theme');
      if (theme) {
        setCurrentTheme(theme as IThemes);
      }
    };
    window.addEventListener('theme-change', checkTheme);
    return () => {
      window.removeEventListener('theme-change', checkTheme);
    };
  }, []);
  return currentTheme;
};

export const changeTheme = (theme: IThemes) => {
  changeWebTheme(
    theme,
    `${process.env.PUBLIC_URL}${webThemes[theme]}`,
    window['link'],
    (link: HTMLLinkElement) => (window['link'] = link) // <link href="theme-dark.css" rel="text/css"/>
  );
  window.dispatchEvent(new Event('theme-change'));
};

export const getTheme = (): IThemes => {
  return (
    (localStorage.getItem(localStorageKeys.theme) as IThemes) ||
    getBrowserTheme()
  );
};

export const getFilterBadgeValue = (filters) => {
  return keys(filters).reduce((acc, i) => {
    const v = filters[i];
    if (isObject(v)) return getFilterBadgeValue(v);
    return !!filters[i] ? acc + 1 : acc;
  }, 0);
};

export const parseDateRange = (dateRange: string): IDateRange => {
  return dateRange === ''
    ? { from: undefined, to: undefined }
    : {
        from: new Date(dateRange.split(dateRangeSeparator)[0]),
        to: new Date(dateRange.split(dateRangeSeparator)[1]),
      };
};
