import { useFormik } from 'formik';
import { noop } from 'lodash';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../app/rootReducer';
import { filteredIpsSelectors } from '../../store/filteredIpsSlice';
import {
  filtersSelectors,
  resetValues,
  setValue,
} from '../../store/tables/filtersSlice';

type FilterFilteredIpsTableFormProps = {
  onCancel: () => void;
};

export const FilterFilteredIpsTableForm: FunctionComponent<FilterFilteredIpsTableFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { onCancel } = props;

  const filters = useTypedSelector(filtersSelectors.filteredIps);
  const types = useTypedSelector(filteredIpsSelectors.filteredIpTypes);

  const formik = useFormik({
    initialValues: filters,
    onSubmit: (values) => {
      dispatch(setValue({ table: 'filteredIps', filters: values }));
      onCancel();
    },
  });

  const handleReset = () => {
    dispatch(resetValues({ table: 'filteredIps' }));
    formik.resetForm();
  };

  useEffect(() => {
    formik.resetForm({ values: filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const isSubmitButtonDisabled = !formik.dirty;

  return (
    <form onSubmit={formik.handleSubmit} className="font-ag2 gray-color-2">
      <div className="form-group row">
        <label htmlFor="name" className="col-sm-4 col-form-label text-break">
          {t('filteredIps.captions.ip')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <input
            type="text"
            className="form-control"
            id="ip"
            name="ip"
            value={formik.values.ip}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="type" className="col-sm-4 col-form-label text-break">
          {t('filteredIps.captions.type')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <select
            className="form-control"
            id="type"
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
          >
            <option value={''}>{t('common.selectAll')}</option>
            {Object.keys(types).map((item) => {
              return (
                <option value={types[item]} label={types[item]} key={item} />
              );
            })}
          </select>
        </div>
      </div>
      <div className="form-group row justify-content-end mt-4 mb-2">
        <div className="mr-3">
          <button
            type="reset"
            className="btn btn-primary"
            onClick={handleReset}
          >
            {t('common.reset')}
          </button>
          <button
            type="button"
            className="btn btn-secondary mx-1"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitButtonDisabled}
          >
            {t('common.apply')}
          </button>
        </div>
      </div>
    </form>
  );
};

FilterFilteredIpsTableForm.defaultProps = {
  onCancel: noop,
};
