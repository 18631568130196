import { useFormik } from 'formik';
import { noop } from 'lodash';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createFeature } from '../../store/featuresSlice';

type CreateFormProps = {
  onCancel?: () => void;
};
export const CreateForm: FunctionComponent<CreateFormProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { onCancel } = props;

  const formik = useFormik({
    initialValues: {
      featureName: '',
    },
    onSubmit: async (values) => {
      const { featureName } = values;
      await dispatch(
        createFeature({
          name: featureName,
        })
      );

      await dispatch(onCancel);
    },
    validateOnMount: true,
  });

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const isFetching = formik.isSubmitting && !formik.isValidating;
  const isSubmitButtonDisabled =
    !!formik.errors.featureName || isFetching || !formik.dirty;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-group row font-ag2">
        <label
          htmlFor="featureName"
          className="col-sm-4 col-form-label text-overflow-ellipsis gray-color-2"
        >
          {t('features.featureName')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <input
            ref={inputRef}
            type="text"
            className="form-control"
            id="featureName"
            name="featureName"
            value={formik.values.featureName}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="form-group row justify-content-end">
        <div className="mr-3">
          <button
            type="button"
            className="btn btn-secondary mr-1"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitButtonDisabled}
          >
            {t('createFeature.submitCreate')}
          </button>
        </div>
      </div>
    </form>
  );
};

CreateForm.defaultProps = {
  onCancel: noop,
};
