import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { userActionsApi } from '../api';
import { RootState } from '../app/rootReducer';
import { UserActionDto } from '../types/dto';
import { LicenseFilter } from '../utils/constants';
import { selectByLicenseFilter } from '../utils/selectors';
import { asyncThunkHandler } from '../utils/sliceHelpers';

const sliceName = 'userActions';

const adapter = createEntityAdapter<UserActionDto>({
  sortComparer: (a, b) => b.instanceCount - a.instanceCount,
});

interface IUserActionsState {
  selectedUserActionId: string;
  licenseFilter: LicenseFilter;
}

const initialState: IUserActionsState = {
  selectedUserActionId: null,
  licenseFilter: LicenseFilter.All,
};

export const fetchUserActions = createAsyncThunk<UserActionDto[], undefined>(
  `${sliceName}/fetchAll`,
  asyncThunkHandler(userActionsApi.getAll)
);

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(initialState),
  reducers: {
    selectUserAction: (state, { payload }: PayloadAction<{ id: string }>) => {
      const { id } = payload;
      state.selectedUserActionId = id;
    },
    resetUserAction: (state) => {
      state.selectedUserActionId = null;
    },
    setLicenseFilter: (state, action) => {
      state.licenseFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserActions.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload);
    });
  },
});

const selectors = adapter.getSelectors((state: RootState) => state[sliceName]);

const filteredByLicenseFilter = createSelector(
  [selectors.selectAll, (state: RootState) => state[sliceName].licenseFilter],
  selectByLicenseFilter
);

export const userActionsSelectors = {
  ...selectors,
  selectedUserActionId: (state: RootState) =>
    state[sliceName].selectedUserActionId,

  licenseFilter: (state: RootState) => state[sliceName].licenseFilter,
  selectUserActionsByLicenseFilter: filteredByLicenseFilter,
};

export const userActionsActions = slice.actions;

export default slice.reducer;
