import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  JWTokenStorage,
  removeRememberMe,
} from 'web-core/lib/utils/JWTAuth/JWToken';
import { authApi } from '../api';
import { ILoginData } from '../types';
import { thunkErrorHandler } from '../utils/sliceHelpers';

const sliceName = 'auth';

type IToken = {
  token: string;
};

export const login = createAsyncThunk<IToken, ILoginData>(
  `${sliceName}/login`,
  async (data, thunkAPI) => {
    try {
      const response = await authApi.login(data);
      return response.data;
    } catch (err) {
      return thunkErrorHandler(err, thunkAPI);
    }
  }
);

export const logout = createAsyncThunk<undefined, undefined>(
  `${sliceName}/logout`,
  async (_, thunkAPI) => {
    try {
      await authApi.logout();
    } catch (err) {
      return thunkErrorHandler(err, thunkAPI);
    }
  }
);

const auth = createSlice({
  name: sliceName,
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      const { token } = payload;
      JWTokenStorage.setTokens({
        accessToken: token,
        rememberMe: true,
      });
    });

    builder.addCase(logout.fulfilled, () => {
      JWTokenStorage.removeTokens();
      removeRememberMe();
    });
  },
});

export const authActions = auth.actions;

export default auth.reducer;
