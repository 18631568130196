import { useFormik } from 'formik';
import { noop } from 'lodash';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../app/rootReducer';
import {
  featuresActions,
  featuresSelectors,
  updateFeature,
} from '../../store/featuresSlice';

type CreateFormProps = {
  selectedFeature: string;
  onCancel?: () => void;
};
export const UpdateForm: FunctionComponent<CreateFormProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedFeatureId = useTypedSelector(
    featuresSelectors.selectedFeatureId
  );
  const selectedFeature = useTypedSelector(
    featuresSelectors.selectById(selectedFeatureId)
  );

  const { onCancel } = props;
  const _selectedFeature = props.selectedFeature;

  const formik = useFormik({
    initialValues: {
      featureName: selectedFeature.name,
    },
    onSubmit: async (values) => {
      await dispatch(
        updateFeature({
          id: selectedFeatureId,
          name: values.featureName,
        })
      );
      await onCancel();
      await dispatch(featuresActions.selectFeature({ id: null }));
    },
    validateOnMount: true,
    validate: (values) => {
      const errors: any = {};

      if (!values.featureName) errors.featureName = t('validation.required');

      return errors;
    },
  });

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.select();
  }, []);

  const isFetching = formik.isSubmitting && !formik.isValidating;

  return (
    <form onSubmit={formik.handleSubmit} className="font-ag2 mb-1">
      <div className="d-flex align-items-center mb-3">
        <div style={{ maxWidth: 150 }} className="gray-color-2 mr-3">
          {t('features.modals.selectedFeature')}
        </div>
        <div className="">{_selectedFeature}</div>
      </div>
      <div className="form-group row">
        <label
          htmlFor="featureName"
          className="gray-color-2 col-sm-4 col-form-label text-overflow-ellipsis"
        >
          {t('features.featureName')}
        </label>
        <div className="col-sm-8 d-flex align-items-center">
          <input
            type="text"
            ref={inputRef}
            className="form-control"
            id="featureName"
            name="featureName"
            value={formik.values.featureName}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="form-group row justify-content-end mb-0">
        <div className="mr-3">
          <button
            type="button"
            className="btn btn-secondary mr-1"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              !!formik.errors.featureName || isFetching || !formik.dirty
            }
          >
            {t('updateFeature.submitUpdate')}
          </button>
        </div>
      </div>
    </form>
  );
};

UpdateForm.defaultProps = {
  onCancel: noop,
};
