import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PageHeader } from 'web-core/lib/web-components/PageHeader/PageHeader';
import { fetchProductReleases } from '../../store/productReleasesSlice';
import { fetchProducts } from '../../store/productsSlice';
import { ProductReleasesTable } from './ProductReleasesTable';
import { ProductReleasesTableButtonGroup } from './ProductReleasesTableButtonGroup';

type ProductReleasesProps = {};
export const ProductReleases: FunctionComponent<ProductReleasesProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchProductReleases());
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <div className="d-flex flex-column h-100 w-100 px-3 pt-3 overflow-hidden">
      <div>
        <PageHeader
          buttons={
            <div>
              <ProductReleasesTableButtonGroup />
            </div>
          }
          title={t('productReleases.header')}
        />
      </div>
      <div className="flex-grow-1 overflow-auto my-4">
        <ProductReleasesTable />
      </div>
    </div>
  );
};
