import { toUpper } from 'lodash';
import { useEffect, useState } from 'react';
import { useGetCurrentUser as useGetUser } from 'web-core/lib/utils/JWTAuth/JWToken';

export interface IUser {
  id: string;
  email: string;
  roles: string[];
  username: string;
}

export const useGetCurrentUser = () => {
  const user = useGetUser({
    postprocessUsername: true,
  });
  const [currentUser, setCurrentUser] = useState(user);

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  return currentUser as IUser;
};

export class User {
  static isWriter(user) {
    return user.roles.map(toUpper).includes('ROLE_WRITER');
  }
}
