import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { compareAsc } from 'date-fns';
import { productReleasesApi } from '../api';
import { RootState } from '../app/rootReducer';
import { ProductDto, ProductReleaseDto } from '../types/dto';
import { asyncThunkHandler } from '../utils/sliceHelpers';

const sliceName = 'productReleases';

const adapter = createEntityAdapter<ProductReleaseDto>({
  sortComparer: (a, b) => compareAsc(new Date(a.date), new Date(b.date)),
});

interface IProductReleasesState {
  selectedId: null | ProductReleaseDto['id'];
}

const initialState: IProductReleasesState = {
  selectedId: null,
};

export const fetchProductReleases = createAsyncThunk<
  ProductReleaseDto[],
  undefined
>(`${sliceName}/fetchAll`, asyncThunkHandler(productReleasesApi.getAll));

export const createProductRelease = createAsyncThunk<
  ProductReleaseDto,
  { productId: ProductDto['id']; version: string; date: string }
>(`${sliceName}/create`, asyncThunkHandler(productReleasesApi.create));

export const updateProductRelease = createAsyncThunk<
  ProductReleaseDto,
  {
    productId: ProductDto['id'];
    version: ProductReleaseDto['version'];
    date: ProductReleaseDto['date'];
    productReleaseId: ProductReleaseDto['id'];
  }
>(`${sliceName}/update`, asyncThunkHandler(productReleasesApi.update));

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(initialState),
  reducers: {
    selectProductRelease: (
      state,
      { payload }: PayloadAction<{ id: typeof initialState['selectedId'] }>
    ) => {
      const { id } = payload;
      state.selectedId = id;
    },
    resetProductRelease: (state) => {
      state.selectedId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductReleases.fulfilled, adapter.setAll)
      .addCase(createProductRelease.fulfilled, adapter.addOne)
      .addCase(updateProductRelease.fulfilled, adapter.upsertOne);
  },
});

const selectors = adapter.getSelectors((state: RootState) => state[sliceName]);

export const productReleasesSelectors = {
  ...selectors,
  selectById: (id: ProductReleaseDto['id']) => (state: RootState) =>
    selectors.selectById(state, id),
  selectedId: (state: RootState) => state[sliceName].selectedId,
};

export const productReleasesActions = slice.actions;

export default slice.reducer;
