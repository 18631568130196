import { FilteredIpsDto } from '../types/dto';
import { requestWithAuth } from './config';

const resource = '/filtered-ips';

export const getAll = () => {
  return requestWithAuth.get(resource);
};

export const create = (data: Partial<FilteredIpsDto>) => {
  return requestWithAuth.post(resource, data);
};

export const update = (data: Partial<FilteredIpsDto>) => {
  const { id, ...rest } = data;
  return requestWithAuth.put(`${resource}/${id}`, rest);
};

export const remove = (data: Partial<FilteredIpsDto>) => {
  return requestWithAuth.delete(`${resource}/${data.id}`);
};

export const getTypes = () => {
  return requestWithAuth.get(`${resource}/types`);
};
